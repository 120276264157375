import React, { useState, useContext, useEffect } from 'react';
import { TableContainer, TableSortLabel, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { useHistory } from 'react-router-dom';
import './notifi.css';

function Notifi() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('alert_handling_track_name');
    const [alertList, setAlertList] = useState([])
    const [apiLoading, setApiLoading] = useState(false);

    useEffect(() => {
        getAlertList()
    }, [])


    const getAlertList = () => {
        setApiLoading(true);
        const body = {
            "SearchText": "",
            "PageSize": 20,
            "CurrentPage": 1,
            "ActivationStatus": "",
            "SortOrder": "Desc",
            "SortCol": "alert_handling_track_name"
        }

        http.post(`api/v1/Alert/Get`,
            body
        )
            .then((res) => {
                setAlertList(res?.Value ?? []);
                setApiLoading(false);
            }).catch((error) => {
                console.log(error);
                NotificationManager.error('Something went wrong', '', 3000);
                setApiLoading(false);
            })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    if (apiLoading) {
        return null;
    }

    return (
        <div className={`mainSection integrationComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`}  >
            <div className='mainSectionHeading withAddnew'>
                <span>Alert Handling</span>
                <Button variant='contained' onClick={() => {
                    history.push(appRoute.AddNotifi);
                }}>Add Alert</Button>
            </div>
            <div className='tableParent'>
                <TableContainer>
                    <Table stickyHeader>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort} />
                        <TableBody>
                            {
                                alertList.length ?
                                    stableSort(alertList, getComparator(order, orderBy))
                                        // alertList
                                        .map((row, index) => {
                                            let count = index + 1;
                                            return (
                                                <TableRow hover key={count} >
                                                    <TableCell align='center'>{count}</TableCell>
                                                    <TableCell title={row.alert_handling_track_name}>{row.alert_handling_track_name}</TableCell>
                                                    <TableCell title={row.alert_handling_track_desc}>{row.alert_handling_track_desc ?? ""}</TableCell>
                                                    <TableCell title={row.notification_interval}>{row.notification_interval ?? ""}</TableCell>
                                                    <TableCell title='View Details'>
                                                        <Button variant='contained' onClick={() => {
                                                            history.push(appRoute.EditNotifi, row)
                                                        }}>Edit</Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={6} align={'center'}>No integrations found</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div >
    )
}

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'date') {
        return (new Date(b[orderBy].split("-").reverse().join("-")).getTime().valueOf() - new Date(a[orderBy].split("-").reverse().join("-")).getTime().valueOf());
    }
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'alert_handling_track_name', label: 'Track Name' },
    { id: 'alert_handling_track_desc', label: 'Track Description' },
    { id: 'notification_interval', label: 'Interval' }
];

const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align='center'>S. No.</TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell >Edit</TableCell>
            </TableRow>
        </TableHead>
    );
}

export default Notifi