import React, { useState, useContext } from 'react';
import { Button } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { StoreContext } from '../../Store/Provider';
import { useHistory } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { useSelector } from 'react-redux';

function OrganizationsAdd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const context = useContext(StoreContext);
    const [inputValues, setInputValues] = useState({
        organization_name: '',
        organization_code: '',
        organization_mail: '',
        organization_contact: '',
        organization_contactperson: ''
        // organization_contactperson: ''
    })

    const addConn = () => {
        if (inputValues.organization_name && inputValues.organization_code && inputValues.organization_mail && inputValues.organization_contact
            // && inputValues.organization_contactperson
        ) {

            // let accessToken = JSON.parse(localStorage.userdata).access_token;
            const body = {
                "organization_id": 0,
                "oper": "I",
                "organization_name": inputValues.organization_name,
                "organization_code": inputValues.organization_code,
                "organization_mail": inputValues.organization_mail,
                "organization_contact": inputValues.organization_contact,
                // "organization_contactperson": inputValues.organization_contactperson,
                "isactive": "Y",
                "createdby": 1
            };
            http.post(`api/v1/Organization/post`,
                body)
                .then((res) => {
                    if (res.Success) {
                        NotificationManager.success(res.ResultMessage, '', 3000);
                        setTimeout(() => {
                            history.push(appRoute.Org)
                        }, 3500);
                    } else {
                        NotificationManager.error(res.ResultMessage, '', 3000);
                    }
                }).catch((error) => {
                    console.log(error)
                    NotificationManager.error('Something went wrong', '', 3000);
                })
        } else {
            NotificationManager.error('Please fill all fields', '', 3000);
        }
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Add New Organization</div>
            <div className='sorceDataDiv'>
                <table className='source'>
                    <tbody>
                        <tr>
                            <th>Organization Name</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.organization_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, organization_name: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>Organization Code</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.organization_code} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, organization_code: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>Organization Mail</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.organization_mail} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, organization_mail: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>Organization Contact</th>
                            <th>:</th>
                            <td className='passTd'>
                                <input type='text' value={inputValues.organization_contact} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, organization_contact: e.target.value })} />
                            </td>
                        </tr>
                        {/* <tr>
                                <th>Organization Contact Person</th>
                                <th>:</th>
                                <td className='passTd'>
                                    <input type='text' value={inputValues.organization_contactperson} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, organization_contactperson: e.target.value })} />
                                </td>
                            </tr> */}
                    </tbody>
                </table>
                <Button variant='contained' className='editNsave' onClick={addConn}>Save</Button>
            </div>
        </div>
    )
}

export default OrganizationsAdd