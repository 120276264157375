import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import http from '../../../api';
import { NotificationManager } from 'react-notifications';
import appRoute from '../../../Route/appRoutes';

const Create = () => {
	const closeSidebar = useSelector(state => state.reducer.closeSidebar)
	const history = useHistory();
	const [destinations, setDestinations] = useState([]);
	const [alertTypes, setAlertTypes] = useState([]);
	const [latencyTypes, setLatencyTypes] = useState([]);
	const [mappingStreams, setMappingStreams] = useState([]);
	const [integrations, setIntegrations] = useState([]);
	const [formData, setFormData] = useState({
		name: '',
		// success_alert: '',
		// failure_alert: '',
		filename_pattern_1: '',
		contents_pattern_1: '',
		contents_pattern_2: '',
		integration: '',
		// latency_type: '',
		// mapping_stream: '',
	});
	const [isEdiFact, setIsEdiFact] = useState(false)
	const [selectedDestinations, setSelectedDestinations] = useState([])

	const hasSpecialCharacters = name => {
		const regex = /[^\w\s]/;
		return regex.test(name);
	}

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleDestinationChange = (e) => {
		const destinations = selectedDestinations;
		if (e.target.checked) {
			destinations.push(e.target.value);
		} else {
			destinations.splice(destinations.indexOf(e.target.value), 1)
		}
		setSelectedDestinations(destinations);
	}

	const getDestinations = () => {
		const body = {
			"SortOrder": "Desc",
			"SortCol": "destination_endpoint_Name"
		}

		http.post(`api/v1/Endpoint/get/destination`, body)
			.then((res) => {
				setDestinations(res?.Value ?? [])
			}).catch((error) => {
				console.log(error)
				NotificationManager.error('Something went wrong', '', 3000);
			})
	}

	const getIntegrations = () => {
        const body = {
            "SortOrder": "desc",
            "SortCol": "integration_name"
        }

        http.post(`api/v1/integration/get`, body)
            .then((res) => {
                setIntegrations(res?.Value ?? []);
            }).catch((error) => {
                NotificationManager.error('Something went wrong', '', 3000);
            })
    }

	const getAlertTypes = () => {
		http.post(`/api/v1/alert/alerttype/Get`)
			.then((res) => {
				setAlertTypes(res?.Value ?? [])
			}).catch((error) => {
				console.log(error)
				NotificationManager.error('Something went wrong in fetching alert types', '', 3000);
			})
	}

	const getLatencyTypes = () => {
		http.post(`/api/v1/Alert/Latency/GET`)
			.then((res) => {
				setLatencyTypes(res?.Value ?? [])
			}).catch((error) => {
				console.log(error)
				NotificationManager.error('Something went wrong in fetching latency types', '', 3000);
			})
	}

	const getMappingStreams = () => {
		http.post(`/api/v1/Message/MappingStream/GET`, {})
			.then((res) => {
				setMappingStreams(res?.Value ?? [])
			}).catch((error) => {
				console.log(error)
				NotificationManager.error('Something went wrong in fetching latency types', '', 3000);
			})
	}

	useEffect(() => {
		getDestinations()
		getIntegrations()
		// getAlertTypes()
		// getLatencyTypes()
		// getMappingStreams()
	}, [])

	const handleSubmit = (e) => {
		e.preventDefault();

		let hasError = false;
		if ( ! formData.integration.length || !formData.name.length || !formData.filename_pattern_1 || !formData.contents_pattern_1.length || !formData.contents_pattern_2.length || !selectedDestinations.length) {
			NotificationManager.error('Please fill all fields', '', 3000);
			hasError = true;
		}

		if (hasSpecialCharacters(formData.filename_pattern_1)) {
			NotificationManager.error(`Please enter filename pattern without special characters`, '', 3000);
			hasError = true;
		}

		
		[1, 2].forEach(num => {
			if (hasSpecialCharacters(formData[`contents_pattern_${num}`])) {
				NotificationManager.error(`Please enter contents pattern ${num} without special characters`, '', 3000);
				hasError = true;
			}
		})

		if ( hasError ) {
			return false;
		}

		const body = {
			"oper": "I",
			"createdby": parseInt(JSON.parse(localStorage.userdata).id),
			"message_type_name": formData.name,
			"message_type_is_edifact_inbound": isEdiFact ? 1 : 0,
			// "success_alert_type_id": parseInt(formData.success_alert),
			// "failure_alert_type_id": parseInt(formData.failure_alert),
			"filename_pattern_01": formData.filename_pattern_1,
			"contents_pattern_01": formData.contents_pattern_1,
			"contents_pattern_02": formData.contents_pattern_2,
			// "latency_type_id": parseInt(formData.latency_type),
			// "mapping_stream_id": parseInt(formData.mapping_stream),
			"destinations": selectedDestinations.join(','),
			"integration_id": parseInt(formData.integration),
		};

		http.post(`/api/v1/Message/MessageType/POST`, body)
			.then((res) => {
				if (res.Success) {
					NotificationManager.success(res.ResultMessage, '', 3000);
					setTimeout(() => {
						history.push(appRoute.MessageTypeList)
					}, 3500);
					e.target.reset();
				} else {
					NotificationManager.error(res.ResultMessage, '', 3000);
				}
			}).catch((error) => {
				console.log(error)
				NotificationManager.error('Something went wrong', '', 3000);
			})

	};

	return (
		<div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
			<div className='mainSectionHeading'>Add Message Type</div> 
			<div className='sorceDataDiv'>
				<form onSubmit={handleSubmit}>
					<div className="row mb-3">
						<div className="col-6">
							<label htmlFor="name" className="form-label">Name</label>
							<input type='text' className='form-control' id="name" name="name" onChange={handleChange} />
						</div>
						<div className="col-6 form-check d-flex gap-3 align-items-center">
							<input type="checkbox" className="form-check-input" name="is_edifact" id="isEdiFact" onChange={(e) => setIsEdiFact(e.target.checked)} />
							<label className="form-check-label" htmlFor="isEdiFact">Is Edifact</label>
						</div>
					</div>

					{/* <div className="row mb-3">
						<div className="col-6">
							<label htmlFor="successAlert" className="form-label">Success Alert</label>
							<select name="success_alert" id="successAlert" className="form-select" onChange={handleChange}>
								<option value="">-- Select --</option>
								{alertTypes.map((alertType, index) => (
									<option key={index} value={alertType.alert_type_id}>{alertType.alert_type_name}</option>
								))}
							</select>
						</div>
						<div className="col-6 form-check">
							<label htmlFor="failureAlert" className="form-label">Failure Alert</label>
							<select name="failure_alert" id="failureAlert" className="form-select" onChange={handleChange}>
								<option value="">-- Select --</option>
								{alertTypes.map((alertType, index) => (
									<option key={index} value={alertType.alert_type_id}>{alertType.alert_type_name}</option>
								))}
							</select>
						</div>
					</div> */}
					<div className="row mb-3">
						<div className="col-6">
							<label htmlFor="integration" className="form-label">Integration</label>
							<select name="integration" id="integration" className="form-select" onChange={handleChange}>
								<option value="">-- Select --</option>
								{integrations.map((integration, index) => (
									<option key={index} value={integration.integration_id}>{integration.integration_name}</option>
								))}
							</select>
						</div>
						<div className="col-6">
							<label htmlFor="filename_pattern_1" className="form-label">Filename Pattern 1</label>
							<input type='text' className='form-control' id="filename_pattern_1" name="filename_pattern_1" onChange={handleChange} />
						</div>
					</div>

					<div className="row mb-3">
						{[1, 2].map(num => (
							<div className="col-6">
								<label htmlFor={`contents_pattern_${num}`} className="form-label">Contents Pattern {num}</label>
								<input type='text' className='form-control' id={`contents_pattern_${num}`} name={`contents_pattern_${num}`} onChange={handleChange} />
							</div>
						))}
					</div>

					{/* <div className="row mb-3">
						<div className="col-6">
							<label htmlFor="latencyType" className="form-label">Latency Type</label>
							<select name="latency_type" id="latencyType" className="form-select" onChange={handleChange}>
								<option value="">-- Select --</option>
								{latencyTypes.map((latencyType, index) => (
									<option key={index} value={latencyType.latency_type_id}>{latencyType.latency_type_name}</option>
								))}
							</select>
						</div>
						<div className="col-6 form-check">
							<label htmlFor="mappingStream" className="form-label">Mapping Stream</label>
							<select name="mapping_stream" id="mappingStream" className="form-select" onChange={handleChange}>
								<option value="">-- Select --</option>
								{mappingStreams.map((mappingStream, index) => (
									<option key={index} value={mappingStream.mapping_stream_id}>{mappingStream.mapping_stream_name}</option>
								))}
							</select>
						</div>
					</div> */}

					<div className="mb-3">
						<label htmlFor="destinations" className="form-label">Destinations</label>
						{destinations.map((destination, index) => (
							<div className="form-check">
								<input className="form-check-input" type="checkbox" name="destinations[]" id={`destination_${index}`} value={destination.destination_endpoint_id} onChange={handleDestinationChange} />
								<label className="form-check-label" htmlFor={`destination_${index}`}>{destination.destination_endpoint_name}</label>
							</div>
						))}
					</div>

					<Button type="submit" variant='contained' className='editNsave'>Save</Button>
				</form>
			</div>
		</div>
	)
}
export default Create;