import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appRoute from '../../../Route/appRoutes';
import http from '../../../api';
import { getComparator, stableSort } from '../../../component/table/function';
import { NotificationManager } from 'react-notifications';
import EnhancedTableHead from '../../../component/table/EnhancedTableHead';

const List = () => {
	const closeSidebar = useSelector(state => state.reducer.closeSidebar);
	const history = useHistory();

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('mapping_stream_name');
	const [mappingStreams, setMappingStreams] = useState([]);
	const [apiLoading, setApiLoading] = useState(false);

	useEffect(() => {
		getMappingStreams()
	}, [])

	const getMappingStreams = () => {
		// let accessToken = JSON.parse(localStorage.userdata).access_token;
		setApiLoading(true);
		const params = {
			"SearchText": "",
			"PageSize": 20,
			"CurrentPage": 1,
			"SortCol": orderBy,
			"SortOrder": order,
			"ActivationStatus": "",
			"Organization": "",
			"Integration": ""
		}

		http.post(`/api/v1/Message/MappingStream/GET`, params)
			.then((res) => {
				setMappingStreams(res?.Value ?? []);
				setApiLoading(false);
			}).catch((error) => {
				console.log(error);
				NotificationManager.error('Something went wrong', '', 3000);
				setApiLoading(false);
			})
	}
	
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	
	if (apiLoading) {
		return null;
    }

	const headCells = [
		{ id: 'stream_name', label: 'Stream Name' },
		{ id: 'url', label: 'URL' },
		{ id: 'description', label: 'Description' },
	];

	return (
		<div className={`mainSection integrationComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`}  >
			<div className='mainSectionHeading withAddnew'>
				<span>Mapping Stream</span>
				<Button variant='contained' onClick={() => {
					history.push(appRoute.MessageMappingStreamCreate);
				}}>Add Mapping Stream</Button>
			</div>
			<div className='container-fluid message-type-container'>
				<TableContainer>
					<Table stickyHeader>
						<EnhancedTableHead
							headCells={headCells}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							action={false} />
						<TableBody>
							{
								mappingStreams.length ?
									stableSort(mappingStreams, getComparator(order, orderBy))
										.map((row, index) => {
											return (
												<TableRow hover key={index}>
													<TableCell align='center'>{index + 1}</TableCell>
													<TableCell title={row.mapping_stream_name}>{row.mapping_stream_name}</TableCell>
													<TableCell title={row.mapping_stream_url}>{row.mapping_stream_url}</TableCell>
													<TableCell title={row.mapping_stream_desc}>{row.mapping_stream_desc}</TableCell>
												</TableRow>
											);
										})
									:
									<TableRow>
										<TableCell colSpan={headCells.length + 1} align={'center'}>No mapping streams found</TableCell>
									</TableRow>
							}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	)
}


export default List;