import React, { useContext } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@material-ui/core';
import Collapse from '@mui/material/Collapse';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import MessageIcon from '@mui/icons-material/Message';
import CommentIcon from '@mui/icons-material/Comment';
import StreamIcon from '@mui/icons-material/Stream';
// import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Link, useLocation } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import { StoreContext } from '../../Store/Provider';
import { ProjectName } from '../../config';

function MainItemsList() {
    const location = useLocation();
    const context = useContext(StoreContext);
    const [messageOpen, setMessageOpen] = React.useState(location.pathname.includes(appRoute.MessageTypeList) || location.pathname.includes(appRoute.MessageMappingStreamList));

    const handleClick = () => {
        setMessageOpen(!messageOpen);
    };

    // const menuList = JSON.parse(localStorage.sidebarData);
    // console.log(menuList)

    if (!context.menuList.length) {
        return null
    }

    return (
        <List className="sidebarList" key="0">
            {
                context.menuList.length && context.menuList.map((element, index) => {
                    if (element.itemName === 'Dashboard') {
                        return <Link to={appRoute.DASHBOARD} key={index} className={location.pathname.includes(appRoute.DASHBOARD) ? 'active' : ''}>
                            <ListItem>
                                <ListItemIcon >
                                    {location.pathname.includes(appRoute.DASHBOARD) ?
                                        <img src={`/${ProjectName}/Assets/dashboard_active.svg`} alt='dashboard_active' />
                                        :
                                        <img src={`/${ProjectName}/Assets/dashboard.svg`} alt='dashboard' />
                                    }
                                </ListItemIcon>
                                <ListItemText>Dashboard</ListItemText>
                            </ListItem>
                        </Link>
                    } else if (element.itemName === 'Integrations') {
                        return <Link to={appRoute.INTEGRATION} key={index} className={location.pathname.includes(appRoute.INTEGRATION) ? 'active' : ''}>
                            <ListItem>
                                <ListItemIcon >
                                    {location.pathname.includes(appRoute.INTEGRATION) ?
                                        <img src={`/${ProjectName}/Assets/integration_active.svg`} alt='integration_active' />
                                        :
                                        <img src={`/${ProjectName}/Assets/integration.svg`} alt='integration' />
                                    }
                                </ListItemIcon>
                                <ListItemText>Integrations</ListItemText>
                            </ListItem>
                        </Link>
                    } else if (element.itemName === 'Connections') {
                        return '';
                        // return <Link to={appRoute.Connections} key={index} className={location.pathname.includes(appRoute.Connections) ? 'active' : ''}>
                        //     <ListItem>
                        //         <ListItemIcon >
                        //             {location.pathname.includes(appRoute.Connections) ?
                        //                 <img src={`/${ProjectName}/Assets/reports_active.svg`} alt='reports_active' />
                        //                 :
                        //                 <img src={`/${ProjectName}/Assets/reports.svg`} alt='reports' />
                        //             }
                        //         </ListItemIcon>
                        //         <ListItemText>Connections</ListItemText>
                        //     </ListItem>
                        // </Link>
                    } else if (element.itemName === 'Organization') {
                        return <Link to={appRoute.Org} key={index} className={location.pathname.includes(appRoute.Org) ? 'active' : ''}>
                            <ListItem>
                                <ListItemIcon >
                                    {location.pathname.includes(appRoute.Org) ?
                                        <BusinessIcon style={{ color: '#c30331' }} />
                                        :
                                        <BusinessIcon style={{ color: '#c30331' }} />
                                    }
                                </ListItemIcon>
                                <ListItemText>Organizations</ListItemText>
                            </ListItem>
                        </Link>
                    } else if (element.itemName === 'Source Endpoint') {
                        return '';
                        // return <Link to={appRoute.Source} key={index} className={location.pathname.includes(appRoute.Source) ? 'active' : ''}>
                        //     <ListItem>
                        //         <ListItemIcon >
                        //             {location.pathname.includes(appRoute.Source) ?
                        //                 <img src={`/${ProjectName}/Assets/logs_active.svg`} alt='logs_active' />
                        //                 :
                        //                 <img src={`/${ProjectName}/Assets/logs.svg`} alt='logs' />
                        //             }
                        //         </ListItemIcon>
                        //         <ListItemText>Source End Points</ListItemText>
                        //     </ListItem>
                        // </Link>
                    } else if (element.itemName === 'Destination Endpoint') {
                        return <Link to={appRoute.Endpoints} key={index} className={location.pathname.includes(appRoute.Endpoints) ? "active" : ""}>
                            <ListItem>
                                <ListItemIcon >
                                    {location.pathname.includes(appRoute.Endpoints) ?
                                        <img src={`/${ProjectName}/Assets/destination_active.svg`} alt='destination_active' />
                                        :
                                        <img src={`/${ProjectName}/Assets/destination.svg`} alt='destination' />
                                    }
                                </ListItemIcon>
                                <ListItemText>Endpoints</ListItemText>
                            </ListItem>
                        </Link>
                    } else if (element.itemName === 'Reports') {
                        return <Link to={appRoute.Reports} key={index} className={location.pathname.includes(appRoute.Reports) ? "active" : ""}>
                            <ListItem>
                                <ListItemIcon >
                                    {location.pathname.includes(appRoute.Reports) ?
                                        <AssessmentIcon style={{ color: '#29235c' }} />
                                        :
                                        <AssessmentIcon style={{ color: '#015480' }} />
                                    }
                                </ListItemIcon>
                                <ListItemText>Reports</ListItemText>
                            </ListItem>
                        </Link>
                    } else if (element.itemName === 'Users') {
                        return <Link to={appRoute.USERS} key={index} className={location.pathname.includes(appRoute.USERS) ? 'active' : ''}>
                            <ListItem>
                                <ListItemIcon >
                                    {location.pathname.includes(appRoute.USERS) ?
                                        <GroupOutlinedIcon style={{ color: '#29235c' }} />
                                        :
                                        <GroupOutlinedIcon style={{ color: '#015480' }} />
                                    }
                                </ListItemIcon>
                                <ListItemText>Users</ListItemText>
                            </ListItem>
                        </Link>
                    } else {
                        return '';
                    }
                })
            }
            {/* <Link to={appRoute.NOTIFI}
                key="alterHandlingLink"
                className={location.pathname.includes(appRoute.NOTIFI) ? 'active' : ''}>
                <ListItem>
                    <ListItemIcon >
                        {location.pathname.includes(appRoute.NOTIFI) ?
                            <GroupOutlinedIcon style={{ color: '#c30331' }} />
                            :
                            <GroupOutlinedIcon style={{ color: '#c30331' }} />
                        }
                    </ListItemIcon>
                    <ListItemText>Alert Handling</ListItemText>
                </ListItem>
            </Link>
            <ListItemButton onClick={handleClick} className={messageOpen ? 'active' : ''} key="messageListItemButton" >
                <ListItemIcon>
                    {messageOpen ?
                        <MessageIcon style={{ color: '#c30331' }} />
                        :
                        <MessageIcon style={{ color: '#c30331' }} />
                    }
                </ListItemIcon>
                <ListItemText primary="Message" />
                {messageOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={messageOpen} timeout="auto" unmountOnExit key="messageListCollapse" >
                <List component="div" disablePadding>
                    <Link key="messageTypeLink"
                        to={appRoute.MessageTypeList}
                        className={location.pathname.includes(appRoute.MessageTypeList) ? 'active' : ''}>
                        <ListItem>
                            <ListItemIcon >
                                {location.pathname.includes(appRoute.MessageTypeList) ?
                                    <CommentIcon style={{ color: '#c30331' }} />
                                    :
                                    <CommentIcon style={{ color: '#c30331' }} />
                                }
                            </ListItemIcon>
                            <ListItemText>Message Type</ListItemText>
                        </ListItem>
                    </Link>
                    <Link key="mappingStreamLink"
                        to={appRoute.MessageMappingStreamList}
                        className={location.pathname.includes(appRoute.MessageMappingStreamList) ? 'active' : ''}>
                        <ListItem>
                            <ListItemIcon >
                                {location.pathname.includes(appRoute.MessageMappingStreamList) ?
                                    <StreamIcon style={{ color: '#c30331' }} />
                                    :
                                    <StreamIcon style={{ color: '#c30331' }} />
                                }
                            </ListItemIcon>
                            <ListItemText>Mapping Stream</ListItemText>
                        </ListItem>
                    </Link>
                </List>
            </Collapse> */}

        </List>
    )
}

export default MainItemsList
