import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort, action }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align='center'>S. No.</TableCell>
                {headCells.map((headCell, index) => (
                    ! headCell?.sortable ? 
                        <TableCell key={headCell.id}>{headCell.label}</TableCell>
                        :
                        <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                ))}
                {
                    action ? <TableCell align='center'></TableCell> : ''
                }
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead