import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
// import { StoreContext } from '../../Store/Provider';
import { useHistory } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import IntegrationMapUser from './IntegrationMapUser';
import FeatureMap from './FeatureMap';
import http from '../../api'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function UserEdit() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    // const context = useContext(StoreContext);
    const [loading, setLoading] = useState(false);
    const [userStatus, setUserStatus] = useState(true);
    // const [showPass, setShowPass] = useState(false);
    // const [showConPass, setShowConPass] = useState(false);
    const [inputValues, setInputValues] = useState({
        user_id: '',
        user_name: '',
        user_loginid: '',
        user_email: '',
        user_contact: '',
        user_password: '',
        conf_password: '',
        user_role: 1
    });
    const [roles, setRoles] = useState([]);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(async () => {
        setLoading(true)
        await setInputValues({
            ...inputValues,
            user_id: history.location.state.user_id,
            user_name: history.location.state.user_name,
            user_loginid: history.location.state.user_loginid,
            user_email: history.location.state.user_email,
            user_contact: history.location.state.user_contact,
            user_password: history.location.state.user_password,
            conf_password: history.location.state.user_password,
            user_role: history.location.state.user_role,
        });
        setUserStatus(history.location.state.isactive)
        await getRoles();
        setLoading(false);
    }, [])

    const getRoles = () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        const body = {}
        http.post(`api/v1/User/Role`,
            body)
            .then((res) => {
                setRoles(res?.Value ?? [])
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('Can not get Roles! Something went wrong', '', 3000);
            })
    }

    const editUser = () => {
        if (!inputValues.user_name) {
            NotificationManager.error('Please fill user Name', '', 3000);
        } else if (!(/^[a-zA-Z ]*$/.test(inputValues.user_name))) {
            NotificationManager.error('Please fill valid user Name', '', 3000);
        } else if (!inputValues.user_loginid) {
            NotificationManager.error('Please fill Login Id', '', 3000);
        } else if (!inputValues.user_email) {
            NotificationManager.error('Please fill Email', '', 3000);
        } else if (!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(inputValues.user_email))) {
            NotificationManager.error('Please fill valid email', '', 3000);
        } else if (!inputValues.user_contact) {
            NotificationManager.error('Please fill Contact', '', 3000);
        } else if (!(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(parseInt(inputValues.user_contact)))) {
            NotificationManager.error('Please fill valid number', '', 3000);
        } else if (!inputValues.user_password) {
            NotificationManager.error('Please fill password', '', 3000);
        } else if (inputValues.user_password !== inputValues.conf_password) {
            NotificationManager.error('Confirm Password does not match', '', 3000);
        } else {
            // let accessToken = JSON.parse(localStorage.userdata).access_token;
            const body = {
                "user_id": inputValues.user_id,
                "oper": "U",
                "user_name": inputValues.user_name,
                "user_loginid": inputValues.user_loginid,
                "user_email": inputValues.user_email,
                "user_contact": inputValues.user_contact,
                "user_password": inputValues.user_password,
                "user_role": inputValues.user_role,
                "isactive": userStatus,
                "createdby": 1
            };
            http.post(`api/v1/User/post`,
                body)
                .then((res) => {
                    if (res.Success) {
                        NotificationManager.success(res.ResultMessage, '', 3000);
                        setTimeout(() => {
                            history.push(appRoute.USERS)
                        }, 3500);
                    } else {
                        NotificationManager.error(res.ResultMessage, '', 3000);
                    }
                }).catch((error) => {
                    console.log(error)
                    NotificationManager.error('Something went wrong', '', 3000);
                })
        }
    }

    if (loading) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Edit User</div>
            {/* <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="User Details" />
                        <Tab label="Integrations" />
                        <Tab label="Features" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}> */}
                    <div className='sorceDataDiv'>
                        {
                            Object.keys(history.location.state).length !== 0 &&
                            <div className="container">
                                <div className="row mb-3">
                                    <label htmlFor="name" className="col-4 col-form-label">User Name:</label>
                                    <div className="col-8">
                                    <input type='text' id="name" value={inputValues.user_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_name: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="login_id" className="col-4 col-form-label">Login Id:</label>
                                    <div className="col-8">
                                        <input type='text' id="login_id" value={inputValues.user_loginid} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_loginid: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="email" className="col-4 col-form-label">Email:</label>
                                    <div className="col-8">
                                        <input type='text' id="email" value={inputValues.user_email} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_email: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="contact" className="col-4 col-form-label">Contact:</label>
                                    <div className="col-8">
                                        <input type='tel' id="contact" value={inputValues.user_contact} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_contact: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="role" className="col-4 col-form-label">Role:</label>
                                    <div className="col-8">
                                        <select className='form-select' id="role" value={inputValues.user_role} onChange={(e) => setInputValues({ ...inputValues, user_role: e.target.value })}>
                                            {
                                                roles.length && roles.map((item, index) => {
                                                    return <option key={index} value={item.role_id} label={item.role_name} />
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="status" className="col-4 col-form-label">Status:</label>
                                    <div className="col-8">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="user_status_yes_no" onChange={(e) => setUserStatus(e.target.checked)} checked={userStatus} />
                                            <label className="form-check-label" htmlFor="user_status_yes_no">{userStatus ? 'Active' : 'Inactive'}</label>
                                        </div>
                                    </div>
                                </div>
                                {/* <tr>
                                    <th>Password</th>
                                    <th>:</th>
                                    <td className='passTd'>
                                        <input type={showPass ? 'text' : 'password'} value={inputValues.user_password} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_password: e.target.value })} />
                                        {
                                            showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Confirm Password</th>
                                    <th>:</th>
                                    <td className='passTd'>
                                        <input type={showConPass ? 'text' : 'password'} value={inputValues.conf_password} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, conf_password: e.target.value })} />
                                        {
                                            showConPass ? <VisibilityIcon onClick={() => setShowConPass(false)} /> : <VisibilityOffIcon onClick={() => setShowConPass(true)} />
                                        }
                                    </td>
                                </tr> */}
                            </div>
                        }
                        <Button variant='contained' className='editNsave' onClick={editUser}>Save</Button>

                    </div>
                {/* </TabPanel>
                <TabPanel value={value} index={1}>
                    <IntegrationMapUser userId={history.location.state.user_id} mail={history.location.state.user_email} integration={history.location.state.user_integrations} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FeatureMap userId={history.location.state.user_id} mail={history.location.state.user_email} features={history.location.state.user_features} />
                </TabPanel>
            </Box> */}
        </div>
    )
}

export default UserEdit

