const appRoute = {
    LOGIN: "/",
    FORGOTPASS: '/ForgotPassword',
    CHANGEPASS: '/ChangePassword',
    RESETPASS: '/ResetPassword/:id',
    INTEGRATION: "/integrations",
    AddIntegration: "/integrations/add",
    EditIntegration: "/integrations/edit/:id",
    DASHBOARD: "/dashboard",
    Connections: "/connections",
    AddCONN: "/connections/add",
    EditConn: "/connections/edit",
    Source: '/source',
    AddSource: '/source/add',
    EditSource: '/source/edit',
    Endpoints: '/endpoints',
    AddEndpoint: '/endpoints/add',
    EditEndpoint: '/endpoints/edit/:id',
    Org: '/organizations',
    AddOrg: '/organizations/add',
    EditOrg: '/organizations/edit',
    Reports: '/reports',
    ReportsLog: '/reports/log/:id',
    LOGS: "/logs",
    USERS: "/users",
    AddUSERS: "/users/add",
    EditUSERS: "/users/edit",
    SOURCE_END_POINT: "/integrations/source_end_point",
    DESTINATION_END_POINT: "/integrations/destination_end_point",
    TIMER: "/integrations/timer",
    NOTIFICATION: "/integrations/notification",
    NOTIFI: '/alertHandle',
    AddNotifi: '/alertHandle/add',
    EditNotifi: '/alertHandle/edit',
    MessageTypeList: '/message/message_type',
    MessageTypeCreate: '/message/message_type/create',
    MessageTypeEdit: '/message/message_type/edit',
    MessageMappingStreamList: '/message/mapping_stream',
    MessageMappingStreamCreate: '/message/mapping_stream/create',
    MessageMappingStreamEdit: '/message/mapping_stream/edit',
}


export default appRoute