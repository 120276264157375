import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { StoreContext } from '../../Store/Provider';
import { useHistory } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { useSelector } from 'react-redux';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import VisibilityIcon from '@material-ui/icons/Visibility';

function SourceAdd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const context = useContext(StoreContext);
    const [connectionList, setConnectionList] = useState([]);
    const [endPointTypeList, setEndPointTypeList] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [showPass, setShowPass] = useState(false);
    // const [showConPass, setShowConPass] = useState(false);
    const [inputValues, setInputValues] = useState({
        source_endpoint_name: '',
        source_endpoint_connection: 1,
        source_endpoint_type_id: 1,
        source_endpoint_working_path: '',
        source_endpoint_archive_path: '',
        source_endpoint_absolute_path: '',
        // connection_name: '',
        // connection_url:'',
        // connection_username:'',
        // connection_password: '',
        // connection_confPassword: ''
    })

    useEffect(async () => {
        setLoading(true);
        await getConn();
        await getEndPointType();
        setLoading(false);
    }, [])

    useEffect(() => {
        setInputValues({ ...inputValues, source_endpoint_connection: connectionList[0]?.connection_id });
    }, [connectionList])

    useEffect(() => {
        setInputValues({ ...inputValues, source_endpoint_type_id: endPointTypeList[0]?.Endpoint_type_id });
    }, [endPointTypeList])

    const getConn = async () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        const body = {
            "SearchText": "",
            "PageSize": 20,
            "CurrentPage": 1,
            "Id": null,
            "ActivationStatus": null,
            "Organization": null,
            "SortOrder": "Desc",
            "SortCol": "Connection_Name"
        };
        http.post(`api/v1/connection/get`,
            body)
            .then((res) => {
                setConnectionList(res?.Value ?? [])
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('Connection list API Fails! Something went wrong', '', 3000);
            })
    }

    const getEndPointType = () => {
        // const body={}
        http.post(`api/v1/Endpoint/get/endpointtype`)
            .then((res) => {
                setEndPointTypeList(res?.Value ?? [])
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('End Point Type List API Fails! Something went wrong', '', 3000);
            })
    }

    const addSource = () => {
        if (inputValues.source_endpoint_name && inputValues.source_endpoint_type_id && inputValues.source_endpoint_working_path && inputValues.source_endpoint_archive_path && inputValues.source_endpoint_absolute_path && inputValues.source_endpoint_connection
            //  && inputValues.connection_name && connection_url && connection_username && inputValues.connection_password && inputValues.connection_confPassword
        ) {
            // if (inputValues.connection_password !== inputValues.connection_confPassword) {
            //     NotificationManager.error('Confirm Password does not match', '', 3000);
            // } else { 
            // }
            // let accessToken = JSON.parse(localStorage.userdata).access_token;
            const body = {
                "source_endpoint_id": 0,
                "oper": "I",
                "source_endpoint_connection": inputValues.source_endpoint_connection,
                "source_endpoint_name": inputValues.source_endpoint_name,
                "source_endpoint_type_id": inputValues.source_endpoint_type_id,
                "source_endpoint_working_path": inputValues.source_endpoint_working_path,
                "source_endpoint_archive_path": inputValues.source_endpoint_archive_path,
                "source_endpoint_absolute_path": inputValues.source_endpoint_absolute_path,
                // "connection_name": inputValues.connection_name,
                // "connection_url":inputValues.connection_url,
                // "connection_username":inputValues.connection_username,
                // "connection_password": inputValues.connection_password,
                "isactive": "Y",
                "createdby": 1
            };
            http.post(`api/v1/Endpoint/post/source`,
                body)
                .then((res) => {
                    if (res.Success) {
                        NotificationManager.success(res.ResultMessage, '', 3000);
                        setTimeout(() => {
                            history.push(appRoute.Source)
                        }, 3500);
                    } else {
                        NotificationManager.error(res.ResultMessage, '', 3000);
                    }
                }).catch((error) => {
                    console.log(error)
                    NotificationManager.error('Something went wrong', '', 3000);
                })
        } else {
            NotificationManager.error('Please fill all fields', '', 3000);
        }
    }

    if (loading) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Add New Source End Point</div>
            <div className='sorceDataDiv'>
                <table className='source'>
                    <tbody>
                        <tr>
                            <th>End Point Name</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.source_endpoint_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_name: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>Working Path</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.source_endpoint_working_path} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_working_path: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>Archieve Path</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.source_endpoint_archive_path} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_archive_path: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>Absolute Path</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.source_endpoint_absolute_path} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_absolute_path: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>End Point Connection</th>
                            <th>:</th>
                            <td>
                                <select value={inputValues.destination_endpoint_connection} onChange={(e) => setInputValues({ ...inputValues, destination_endpoint_connection: e.target.value })} className='form-select' >
                                    {
                                        connectionList?.length !== 0 &&
                                        connectionList?.map((ele, index) => {
                                            return (
                                                <option value={ele.connection_id} key={index}>{ele.connection_name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {/* <input type='text' value={inputValues.source_endpoint_connection} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_connection: e.target.value })} /> */}
                            </td>
                        </tr>
                        <tr>
                            <th>End Point Type</th>
                            <th>:</th>
                            <td>
                                <select value={inputValues.source_endpoint_type_id} onChange={(e) => setInputValues({ ...inputValues, source_endpoint_type_id: e.target.value })} className='form-select' >
                                    {
                                        endPointTypeList?.length !== 0 ?
                                            endPointTypeList?.map((ele, index) => {
                                                return (
                                                    <option value={ele.Endpoint_type_id} key={index} label={ele.Endpoint_type_name} />
                                                )
                                            })
                                            : "No end point type found"
                                    }
                                </select>
                                {/* <input type='text' value={inputValues.source_endpoint_type_id} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_type_id: e.target.value })} /> */}
                            </td>
                        </tr>
                        {/* <tr>
                            <th>Connection Name</th>
                            <th>:</th>
                            <td><input type='text' value={inputValues.connection_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_name: e.target.value })} /></td>
                        </tr> */}
                        {/*<tr>
                                <th>Connection URL</th>
                                <th>:</th>
                                <td><input type='password' value={inputValues.connection_url} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_url: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>User Name</th>
                                <th>:</th>
                                <td><input type='password' value={inputValues.connection_username} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_username: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Connection Password</th>
                                <th>:</th>
                                <td className='passTd'>
                                    <input type={showPass ? 'text' : 'password'} value={inputValues.connection_password} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_password: e.target.value })} />
                                    {
                                        showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Confirm Password</th>
                                <th>:</th>
                                <td className='passTd'>
                                    <input type={showConPass ? 'text' : 'password'} value={inputValues.connection_confPassword} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_confPassword: e.target.value })} />
                                    {
                                        showConPass ? <VisibilityIcon onClick={() => setShowConPass(false)} /> : <VisibilityOffIcon onClick={() => setShowConPass(true)} />
                                    }
                                </td>
                            </tr>*/}
                    </tbody>
                </table>
                <Button variant='contained' className='editNsave' onClick={addSource}>Save</Button>
            </div>
        </div>
    )
}

export default SourceAdd
