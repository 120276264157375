import { startOfWeek, endOfWeek,  subDays, addDays, startOfMonth, endOfMonth, addMonths, format } from 'date-fns';
import { NotificationManager } from "react-notifications";

const descendingComparator = (a, b, orderBy) => {
    if (orderBy === 'createdon') {
        // return (new Date(b[orderBy].split("-").reverse().join("-")).getTime().valueOf() - new Date(a[orderBy].split("-").reverse().join("-")).getTime().valueOf());
        return (new Date(b[orderBy]) - new Date(a[orderBy]))
    }
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const showErrorMessage = (message) => {
    NotificationManager.error(message, '', 3000);
}

const showSuccessMessage = (message) => {
    NotificationManager.success(message, '', 3000);
}

const validateCommaSeparatedEmailList = (emailList) => {
    const emails = emailList.split(',').map(email => email.trim());
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    for (let email of emails) {
        if (!emailRegex.test(email)) {
            return false;
        }
    }

    return true;
}

const getPredefinedDateRanges = function() {
    const placement = 'left';
    
    return [
        {
          label: 'Today',
          value: [new Date(), new Date()],
          placement: placement
        },
        {
          label: 'Yesterday',
          value: [addDays(new Date(), -1), addDays(new Date(), -1)],
          placement: placement
        },
        {
          label: 'This week',
          value: [startOfWeek(new Date()), endOfWeek(new Date())],
          placement: placement
        },
        {
          label: 'Last 7 days',
          value: [subDays(new Date(), 6), new Date()],
          placement: placement
        },
        {
          label: 'Last 30 days',
          value: [subDays(new Date(), 29), new Date()],
          placement: placement
        },
        {
          label: 'This month',
          value: [startOfMonth(new Date()), new Date()],
          placement: placement
        },
        {
          label: 'Last month',
          value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
          placement: placement
        },
        {
          label: 'This year',
          value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
          placement: placement
        },
        {
          label: 'Last year',
          value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
          placement: placement
        },
        {
          label: 'All time',
          value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
          placement: placement
        },
        {
          label: 'Last week',
          closeOverlay: false,
          value: value => {
            const [start = new Date()] = value || [];
            return [
              addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
              addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
            ];
          },
          appearance: 'default'
        },
        {
          label: 'Next week',
          closeOverlay: false,
          value: value => {
            const [start = new Date()] = value || [];
            return [
              addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
              addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
            ];
          },
          appearance: 'default'
        }
    ];
}

export { descendingComparator, getComparator, stableSort, showErrorMessage, showSuccessMessage, validateCommaSeparatedEmailList, getPredefinedDateRanges };