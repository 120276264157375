import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import TimerIcon from '@material-ui/icons/Timer';
import React, { useEffect, useState } from 'react';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import { Link, useLocation } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import { ProjectName } from '../../config';

function InternalItems() {
    const location = useLocation();
    const [integration_name, setIntegration_name] = useState('')
    const [integrationId, setIntegrationId] = useState(0);

    useEffect(() => {
        setIntegrationId(new URLSearchParams(location.search).get("integration_id"));
        setIntegration_name(new URLSearchParams(location.search).get("integration_name"));
    }, [location]);

    return (
        <List className="sidebarList integrationSidebarList">
            <ListItem className='sidebarHeading' title={integration_name} style={{ paddingRight: '1rem' }}>
                <ListItemIcon>
                    <AccountTreeOutlinedIcon />
                </ListItemIcon>
                <ListItemText>{integration_name.toUpperCase()}</ListItemText>
            </ListItem>
            {/* <ListItem style={{ color: '#e2001a' }}>
            <ListItemIcon >
                {(location.pathname === '/integrations/${context.integrationName}/jobs' || location.pathname === '/integrations/${context.integrationName}/jobs/') ?
                    <svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path d="M10.208 7.308a1.09 1.09 0 010 1.483l-3.515 3.71 3.515 3.708a1.09 1.09 0 010 1.483.957.957 0 01-1.405 0l-3.866-4.08a1.635 1.635 0 010-2.225l3.866-4.08a.957.957 0 011.405 0zm3.584 0a.957.957 0 011.405 0l3.866 4.08c.583.614.583 1.61 0 2.225l-3.866 4.08a.957.957 0 01-1.405 0 1.09 1.09 0 010-1.484l3.515-3.708-3.515-3.71a1.09 1.09 0 010-1.483z" fill="#e2001a"></path></svg>
                    :
                    <svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path d="M10.208 7.308a1.09 1.09 0 010 1.483l-3.515 3.71 3.515 3.708a1.09 1.09 0 010 1.483.957.957 0 01-1.405 0l-3.866-4.08a1.635 1.635 0 010-2.225l3.866-4.08a.957.957 0 011.405 0zm3.584 0a.957.957 0 011.405 0l3.866 4.08c.583.614.583 1.61 0 2.225l-3.866 4.08a.957.957 0 01-1.405 0 1.09 1.09 0 010-1.484l3.515-3.708-3.515-3.71a1.09 1.09 0 010-1.483z" fill="#c30331" ></path></svg>
                }
            </ListItemIcon>
            <ListItemText>Jobs</ListItemText>
        </ListItem> */}
            <Link to={`${appRoute.SOURCE_END_POINT}?integration_id=${integrationId}&integration_name=${integration_name}`} className={location.pathname.includes(`${appRoute.SOURCE_END_POINT}`) ? "active" : ""}>
                <ListItem>
                    <ListItemIcon >
                        {location.pathname.includes(`${appRoute.SOURCE_END_POINT}`) ?
                            <img src={`/${ProjectName}/Assets/source_active.svg`} alt='source_active' />
                            :
                            <img src={`/${ProjectName}/Assets/source.svg`} alt='source' />
                        }
                    </ListItemIcon>
                    <ListItemText>Source End Point</ListItemText>
                </ListItem>
            </Link>
            <Link to={`${appRoute.DESTINATION_END_POINT}?integration_id=${integrationId}&integration_name=${integration_name}`} className={location.pathname.includes(`${appRoute.DESTINATION_END_POINT}`) ? "active" : ""}>
                <ListItem>
                    <ListItemIcon >
                        {location.pathname.includes(`${appRoute.DESTINATION_END_POINT}`) ?
                            <img src={`/${ProjectName}/Assets/destination_active.svg`} alt='destination_active' />
                            :
                            <img src={`/${ProjectName}/Assets/destination.svg`} alt='destination' />
                        }
                    </ListItemIcon>
                    <ListItemText>Destination End Point</ListItemText>
                </ListItem>
            </Link>
            <Link to={`${appRoute.TIMER}?integration_id=${integrationId}&integration_name=${integration_name}`} className={location.pathname.includes(`${appRoute.TIMER}`) ? "active" : ""}>
                <ListItem>
                    <ListItemIcon >
                        {location.pathname.includes(`${appRoute.TIMER}`) ?
                            <TimerIcon style={{ color: '#e2001a' }} />
                            :
                            <TimerIcon style={{ color: '#c30331' }} />
                        }
                    </ListItemIcon>
                    <ListItemText>Timer</ListItemText>
                </ListItem>
            </Link>
            <Link to={`${appRoute.NOTIFICATION}?integration_id=${integrationId}&integration_name=${integration_name}`} className={location.pathname.includes(`${appRoute.NOTIFICATION}`) ? "active" : ""}>
                <ListItem>
                    <ListItemIcon >
                        {location.pathname.includes(`${appRoute.NOTIFICATION}`) ?
                            <NotificationsNoneIcon style={{ color: '#e2001a' }} />
                            :
                            <NotificationsNoneIcon style={{ color: '#c30331' }} />
                        }
                    </ListItemIcon>
                    <ListItemText>Notifications</ListItemText>
                </ListItem>
            </Link>
        </List>
    )
}

export default InternalItems
