import React, { createContext, useState } from 'react';

const StoreContext = createContext();

function Provider(props) {
    const [closeSidebar, setCloseSidebar] = useState(false);
    const [pieIndex, setPieIndex] = useState(0);
    const [menuList, setmenuList] = useState([]);

    return (
        <StoreContext.Provider
            value={{
                closeSidebar,
                pieIndex,
                menuList,
                // menu,
                setCloseSidebar,
                setPieIndex,
                setmenuList
            }}
        >
            {props.children}
        </StoreContext.Provider>
    )
}

export { Provider, StoreContext }
