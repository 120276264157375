import { IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@material-ui/core';
import { RemoveRedEyeOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import EnhancedTableHead from '../../component/table/EnhancedTableHead';
import http from '../../api';
import { getComparator, showErrorMessage, stableSort } from '../../component/table/function';
import './reports.css';

const ReportsLog = () => {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const { id } = useParams();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('message_filename');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [report, setReport] = useState(0);
    const [apiLoading, setApiLoading] = useState(true);

    const getReportDetails = (e) => {
        const params = {
            "SearchText": "",
            "PageSize": rowsPerPage,
            "CurrentPage": page + 1,
            // "MessageStatus": null,
            // "Organization": null,
            // "integration": null,
            "SortOrder": order,
            "SortCol": orderBy
        }

        http.post(`/api/v1/Integration/GETReportDetail?message_id=${id}`, params)
            .then((res) => {
                setTotalRecords(res?.RecordCount ?? 0)
                setReport(res?.Value ?? [])
                setApiLoading(false)
            }).catch((error) => {
                showErrorMessage('Something went wrong');
            })
    }

    useEffect(() => {
        getReportDetails()
    }, [rowsPerPage, page, order, orderBy])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    if (apiLoading) {
        return null;
    }

    const headCells = [
        { id: 'message_status', label: 'Status', sortable: true },
        { id: 'message_processdate', label: 'Date' , sortable: true},
        { id: 'additional_data', label: 'Additional Data', sortable: true },
    ];

    return (
        <div className={`mainSection integrationComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>
                <span>Reports Log</span>
                {/* <Button variant='contained' onClick={() => {
                    // history.push(appRoute.AddEndpoint);
                }}>Add Report</Button> */}
            </div>
            <div className="main-section-sub-heading">
                <span>{ report[0].message_integration }</span>
                <span className="title-seprator">-</span>
                <span>{ report[0].message_filename }</span>
            </div>
            <div className='tableParent'>
                <TableContainer>
                    <Table stickyHeader>
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            actio={false} />
                        <TableBody>
                            {
                                report.length ?
                                    stableSort(report, getComparator(order, orderBy))
                                        // reportsList
                                        .map((row, index) => {
                                            let count = index + 1;
                                            let date = moment(row.message_processdate).format('DD-MM-yyy H:m A');
                                            return (
                                                <TableRow hover key={count}>
                                                    <TableCell align='center'>{index + 1}</TableCell>
                                                    <TableCell title={row.message_status}>{row.message_status}</TableCell>
                                                    <TableCell title={date}>{date}</TableCell>
                                                    <TableCell title={row.additional_data}>{row.additional_data}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={6} align={'center'}>No log found</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>

        </div >
    )
}

export default ReportsLog;