import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { showErrorMessage, showSuccessMessage } from '../../component/table/function';

function EndpointAdd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true)
    const { id } = useParams();
    const isEdit = id ? true : false;
    const [endpointTypes, setEndpointTypes] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        endpoint_type: '',
        hostname: '',
        port: '',
        username: '',
        password: '',
        url: '',
        relative_path: '',
        absolute_path: '',
        key: '',
    })

    useEffect(() => {
        http.post('/api/v1/Endpoint/GET/endpointtype')
            .then((res) => {
                setEndpointTypes(res?.Value ?? [])
                if ( ! isEdit ) {
                    setIsLoading(false)
                }
            })

        if ( isEdit ) {
            const body = {
                "id": id,
            }
    
            http.post(`api/v1/Endpoint/EndpointMaster/Get`, body)
                .then((res) => {
                    if ( res.Value ) {
                        const endpoint = res.Value ? res.Value[0] : []
                        setFormData({
                            name: endpoint.endpoint_name,
                            endpoint_type: endpoint.endpoint_type_name.trim(),
                            hostname: endpoint.endpoint_hostname ?? '',
                            port: endpoint.endpoint_port ?? '',
                            username: endpoint.endpoint_username ?? '',
                            password: endpoint.endpoint_password ?? '',
                            relative_path: endpoint.endpoint_relativepath ?? '',
                            absolute_path: endpoint.endpoint_absolutepath ?? '',
                            url: endpoint.endpoint_url ?? '',
                            key: endpoint.endpoint_key ?? '',
                        })
                        setIsLoading(false)
                    }
                }).catch((error) => {
                    console.log(error);
                    showErrorMessage('Something went wrong');
                })
        }
    }, [id, isEdit])

    const handleFormFieldChange = (e) => {
    	setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if ( ! formData.name.length || ! formData.endpoint_type.length) {
            showErrorMessage('Please fill all fields');
			return
        }

        const endpointType = formData.endpoint_type.toLowerCase();

        if ( ['sftp', 'ftp', 'email'].indexOf(endpointType) !== -1  && ( ! formData.hostname.length || ! formData.username.length || ! formData.password.length || ! formData.relative_path.length ) ) {
            showErrorMessage('Please fill all fields');
			return
        }

        if ( ['api'].indexOf(endpointType) !== -1  && ! formData.url.length ) {
            showErrorMessage('URL is required field.');
			return
        }

        // if ( ['cw1'].indexOf(endpointType) !== -1  && ( ! formData.username.length || ! formData.password.length ) ) {
        //     showErrorMessage('Please fill all fields');
		// 	return
        // }

        if ( ['folderlocation'].indexOf(endpointType) !== -1  && ! formData.absolute_path.length ) {
            showErrorMessage('Please fill all fields');
			return
        }

        let endpoint = endpointTypes.filter(endpointType => endpointType.Endpoint_type_desc.trim() === formData.endpoint_type.trim())[0];
        if ( ! endpoint ) {
            showErrorMessage('Invalid endpoint type.');
			return
        }

        const body = {
            "oper": isEdit ? "U" : "I",
            "isactive": true,
            "createdby": parseInt(JSON.parse(localStorage.userdata).id),
            "endpoint_name": formData.name,
            "endpoint_type_id": endpoint.Endpoint_type_id,
            "endpoint_hostname": formData.hostname,
            "endpoint_username": formData.username,
            "endpoint_password": formData.password,
            "endpoint_port": formData.port,
            "endpoint_url": formData.url,
            "endpoint_key": formData.key,
            "endpoint_relativepath": formData.relative_path,
            "endpoint_absolutepath": formData.absolute_path,
        };

        if ( isEdit ) {
            body["endpoint_id"] = id;
        }
        
        http.post(`/api/v1/Endpoint/EndpointMaster/POST`, body)
            .then((res) => {
                if (res.Success) {
                    showSuccessMessage(res.ResultMessage)
                    setTimeout(() => {
                        history.push(appRoute.Endpoints)
                    }, 3500);
                    e.target.reset();
                } else {
                    showErrorMessage(res.ResultMessage);
                }
            }).catch((error) => {
                console.log(error)
                showErrorMessage('Something went wrong');
            })
    }

    if ( isLoading ) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>{isEdit ? 'Edit' : 'Add New'} Endpoint</div>
            <div className='sorceDataDiv mt-3'>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <label htmlFor="name" className="col-4 col-form-label">Name:</label>
                        <div className="col-8">
                            <input type='text' value={formData.name} id="name" name="name" className='form-control' onChange={handleFormFieldChange} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="endpoint_type" className="col-4 col-form-label">Endpoint Type:</label>
                        <div className="col-8">
                            <select className="form-select" name="endpoint_type" id="endpoint_type" value={formData.endpoint_type} onChange={handleFormFieldChange}>
                                <option value="">-- Select --</option>
                                {endpointTypes.map((endpointType, index) => (
                                    <option key={index} value={endpointType.Endpoint_type_desc.trim()}>{endpointType.Endpoint_type_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {
                        (formData.endpoint_type && ['sftp', 'ftp', 'email'].indexOf(formData.endpoint_type.toLowerCase()) !== -1) 
                        ? (<>
                            <div className="row mb-3">
                                <label htmlFor="hostname" className="col-4 col-form-label">Hostname:</label>
                                <div className="col-8">
                                    <input type='text' value={formData.hostname} id="hostname" name="hostname" className='form-control' onChange={handleFormFieldChange} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="port" className="col-4 col-form-label">Port:</label>
                                <div className="col-8">
                                    <input type='text' value={formData.port} id="port" name="port" className='form-control' onChange={handleFormFieldChange} />
                                </div>
                            </div>
                            </>) : ''
                    }

                    {
                        (formData.endpoint_type && (formData.endpoint_type.toLowerCase() === 'cw1' || formData.endpoint_type.toLowerCase() === 'api'))
                        ? (
                            <div className="row mb-3">
                                <label htmlFor="url" className="col-4 col-form-label">URL:</label>
                                <div className="col-8">
                                    <input type='text' value={formData.url} id="url" name="url" className='form-control' onChange={handleFormFieldChange} />
                                </div>
                            </div>
                        )
                        : ''
                    }

                    {
                        (formData.endpoint_type && formData.endpoint_type.toLowerCase() === 'api')
                        ? (
                            <div className="row mb-3">
                                <label htmlFor="key" className="col-4 col-form-label">Key:</label>
                                <div className="col-8">
                                    <input type='text' value={formData.key} id="key" name="key" className='form-control' onChange={handleFormFieldChange} />
                                </div>
                            </div>
                        )
                        : ''
                    }

                    {
                        (formData.endpoint_type && ['sftp', 'ftp', 'email', 'cw1'].indexOf(formData.endpoint_type.toLowerCase()) !== -1)
                        ? (<>
                            <div className="row mb-3">
                                <label htmlFor="username" className="col-4 col-form-label">Username:</label>
                                <div className="col-8">
                                    <input type='text' value={formData.username} id="username" name="username" className='form-control' onChange={handleFormFieldChange} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="password" className="col-4 col-form-label">Password:</label>
                                <div className="col-8">
                                    <input type='text' value={formData.password} id="password" name="password" className='form-control' onChange={handleFormFieldChange} />
                                </div>
                            </div>
                        </>)
                        :''
                    }

                    {
                        (formData.endpoint_type && ['sftp', 'ftp', 'email'].indexOf(formData.endpoint_type.toLowerCase()) !== -1) 
                        ? (<div className="row mb-3">
                                <label htmlFor="relative_path" className="col-4 col-form-label">Relative Path:</label>
                                <div className="col-8">
                                    <input type='text' value={formData.relative_path} id="relative_path" name="relative_path" className='form-control' onChange={handleFormFieldChange} />
                                </div>
                            </div>) : ''
                    }
                
                    {
                        (formData.endpoint_type && ['folderlocation'].indexOf(formData.endpoint_type.toLowerCase()) !== -1)
                        ? (<>
                            <div className="row mb-3">
                                <label htmlFor="absolute_path" className="col-4 col-form-label">Absolute Path:</label>
                                <div className="col-8">
                                    <input type='text' value={formData.absolute_path} id="absolute_path" name="absolute_path" className='form-control' onChange={handleFormFieldChange} />
                                </div>
                            </div>
                        </>)
                        :''
                    }
                    <Button type="submit" variant='contained' className='editNsave'>Save</Button>
                </form>
            </div>
        </div >
    )
}

export default EndpointAdd
