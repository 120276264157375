import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { ProjectName } from '../../config';

function ChangePassword() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const [confpassword, setConfPassword] = useState("")
    const [password, setPassword] = useState("")
    const [oldPassword, setOldPassword] = useState("")
    const [showPass, setShowPass] = useState(false);
    const [showOldPass, setShowOldPass] = useState(false);
    const [showConPass, setShowConPass] = useState(false);
    const [passStrength, setPassStrength] = useState('Too Short');
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const checkPass = (e) => {
        setPassword(e.target.value)
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/.test(e.target.value)) {
            //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
            setPassStrength('Strong')
        } else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(e.target.value)) {
            // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
            setPassStrength('Good')
        } else if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(e.target.value)) {
            //Minimum eight characters, at least one letter and one number
            setPassStrength('Medium')
        } else if (e.target.value.length > 4) {
            //greater than 4
            setPassStrength('Weak')
        } else {
            setPassStrength('Too Short')
        }
    }

    const changePass = () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        let userEmail = JSON.parse(localStorage.userdata).email;
        if (!oldPassword || !password || !confpassword) {
            NotificationManager.error("Please fill all fields")
        } else if (passStrength !== 'Strong') {
            NotificationManager.error("Password criteria does not match")
        } else if (oldPassword === password) {
            NotificationManager.error("Old Password and New Password are same")
        } else if (password !== confpassword) {
            NotificationManager.error("Password does not match")
        } else {
            const body = {
                "user_email": userEmail,
                "old_password": oldPassword,
                "user_password": password
            }
            http
                .post(`api/v1/User/password/change`,
                    body)
                .then(res => {
                    if (res.Success) {
                        NotificationManager.success(res.ResultMessage, '', 3000);
                        setOpen(false);
                        setTimeout(() => {
                            history.push(appRoute.INTEGRATION);
                        }, 3500)
                    } else {
                        NotificationManager.error(res.ResultMessage, '', 3000);
                        setOpen(false);
                    }
                })
                .catch(error => {
                    console.log(error)
                    NotificationManager.error('Something went wrong', '', 3000);
                    setOpen(false);
                })
        }
    }

    return (
        <div className={`mainSection reportsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='loginComponent container-fluid' style={{ background: `url("/${ProjectName}/Assets/map.jpg") 0% 0% / cover` }}>
                <div className='row'>
                    <div className='col-12 px-0 loginFormSection'>
                        <div className='loginDiv'>
                            <h5>Change Password!</h5>
                            <div className="form-floating mb-3">
                                <input
                                    type={showOldPass ? 'text' : 'password'}
                                    className="form-control"
                                    placeholder="Old Password"
                                    value={oldPassword}
                                    onChange={(e) => { setOldPassword(e.target.value) }}
                                />
                                <label htmlFor="floatingPassword">Old Password</label>
                                {
                                    showOldPass ? <VisibilityIcon onClick={() => setShowOldPass(false)} /> : <VisibilityOffIcon onClick={() => setShowOldPass(true)} />
                                }
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type={showPass ? 'text' : 'password'}
                                    className="form-control"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => { checkPass(e) }}
                                />
                                <label htmlFor="floatingPassword">Password</label>
                                {
                                    showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                                }
                                {
                                    password &&
                                    <p className='showpassword passStrength text-end' style={{
                                        color:
                                            passStrength === 'Too Short' ? 'var(--bs-danger)' : passStrength === 'Weak' ? 'var(--bs-orange)' : passStrength === 'Medium' ? 'var(--bs-yellow)' : passStrength === 'Good' ? 'var(--bs-primary)' : 'var(--bs-success)'
                                    }}>{passStrength}</p>
                                }
                            </div>
                            {
                                (password && passStrength !== 'Strong') &&
                                <p className="error resetPass">Minimum 8 characters, atleast one special character, one uppercase, one lowercase letter and one number</p>
                            }
                            <div className="form-floating mb-3">
                                <input
                                    type={showConPass ? 'text' : 'password'}
                                    className="form-control"
                                    placeholder="Password"
                                    value={confpassword}
                                    onChange={(e) => { setConfPassword(e.target.value) }}
                                />
                                <label htmlFor="floatingPassword">Confirm Password</label>
                                {
                                    showConPass ? <VisibilityIcon onClick={() => setShowConPass(false)} /> : <VisibilityOffIcon onClick={() => setShowConPass(true)} />
                                }
                            </div>
                            <button type="button" className="btn btn-lg" onClick={changePass}>Change Password</button>
                        </div>
                    </div>
                    {/* <div className='col-6 px-0 loginbannerSection'>
                        <img src='/charlesKendall/Assets/Logo.svg' alt='logo' />
                    </div> */}
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div >

        </div>
    )
}

export default ChangePassword
