import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { showErrorMessage, showSuccessMessage } from '../../component/table/function';

function UserAdd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    // const context = useContext(StoreContext);
    const [showPass, setShowPass] = useState(false);
    // const [showConPass, setShowConPass] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userStatus, setUserStatus] = useState(true);
    const [passStrength, setPassStrength] = useState('Too Short');
    const [loading, setLoading] = useState(true);
    const [inputValues, setInputValues] = useState({
        user_name: '',
        user_loginid: '',
        user_email: '',
        user_contact: '',
        user_password: '',
        conf_password: '',
        user_role: 1
    })

    useEffect(async () => {
        getRoles();
    }, [])

    useEffect(() => {
        setInputValues({ ...inputValues, user_role: roles[0]?.role_id });
    }, [roles])


    const getRoles = () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        const body = {}
        http.post(`api/v1/User/Role`,
            body)
            .then((res) => {
                setRoles(res?.Value ?? [])
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                showErrorMessage('Can not get Roles! Something went wrong');
            })
    }

    const addUser = () => {
        if (!inputValues.user_name) {
            showErrorMessage('Please fill user Name');
        } else if (!(/^[a-zA-Z ]*$/.test(inputValues.user_name))) {
            showErrorMessage('Please fill valid user Name');
        } else if (!inputValues.user_loginid) {
            showErrorMessage('Please fill Login Id');
        } else if (!inputValues.user_email) {
            showErrorMessage('Please fill Email');
        } else if (!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(inputValues.user_email))) {
            showErrorMessage('Please fill valid email');
        } else if (!inputValues.user_contact) {
            showErrorMessage('Please fill Contact');
        } else if (!(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(parseInt(inputValues.user_contact)))) {
            showErrorMessage('Please fill valid number');
        } else if (!inputValues.user_password) {
            showErrorMessage('Please fill password');
        }
        // else if (passStrength !== 'Strong') {
        //     showErrorMessage("Password criteria doe")
        // } 
        // else if (inputValues.user_password !== inputValues.conf_password) {
        //     showErrorMessage('Confirm Password does not match');
        // } 
        else {
            // let accessToken = JSON.parse(localStorage.userdata).access_token;
            const body = {
                "user_id": 0,
                "oper": "I",
                "user_name": inputValues.user_name,
                "user_loginid": inputValues.user_loginid,
                "user_email": inputValues.user_email,
                "user_contact": inputValues.user_contact,
                "user_password": inputValues.user_password,
                "user_role": inputValues.user_role,
                "isactive": userStatus,
                "createdby": 1
            };
            http.post(`api/v1/User/post`,
                body)
                .then((res) => {
                    if (res.Success) {
                        showSuccessMessage(res.ResultMessage);
                        setTimeout(() => {
                            history.push(appRoute.USERS)
                        }, 3500);
                    } else {
                        showErrorMessage(res.ResultMessage);
                    }
                }).catch((error) => {
                    console.log(error)
                    showErrorMessage('Something went wrong');
                })

        }
    }

    const checkPass = (e) => {
        setInputValues({ ...inputValues, user_password: e.target.value })
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/.test(e.target.value)) {
            //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
            setPassStrength('Strong');
        } else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(e.target.value)) {
            // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
            setPassStrength('Good');
        } else if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(e.target.value)) {
            //Minimum eight characters, at least one letter and one number
            setPassStrength('Medium');
        } else if (e.target.value.length > 4) {
            //greater than 4
            setPassStrength('Weak');
        } else {
            setPassStrength('Too Short');
        }
    }

    if (loading) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Add New User</div>
            <div className='sorceDataDiv'>
                <div className="row mb-3">
                    <label htmlFor="name" className="col-4 col-form-label">User Name:</label>
                    <div className="col-8">
                        <input type='text' id="name" value={inputValues.user_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_name: e.target.value })} />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="login_id" className="col-4 col-form-label">Login Id:</label>
                    <div className="col-8">
                        <input type='text' id="login_id" value={inputValues.user_loginid} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_loginid: e.target.value })} />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="email" className="col-4 col-form-label">Email:</label>
                    <div className="col-8">
                        <input type='text' id="email" value={inputValues.user_email} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_email: e.target.value })} />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="contact" className="col-4 col-form-label">Contact:</label>
                    <div className="col-8">
                        <input type='tel' id="contact" value={inputValues.user_contact} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_contact: e.target.value })} />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="role" className="col-4 col-form-label">Role:</label>
                    <div className="col-8">
                        {/* <input type='text' value={inputValues.user_role} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, user_role: e.target.value })} /> */}
                        <select className='form-select' id="role" value={inputValues.user_role} onChange={(e) => setInputValues({ ...inputValues, user_role: e.target.value })}>
                            {
                                roles.length && roles.map((item, index) => {
                                    return <option key={index} value={item.role_id} label={item.role_name} />
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="password" className="col-4 col-form-label">Password:</label>
                    <div className="col-8">
                        <div className="passId">
                            <input type={showPass ? 'text' : 'password'} id="password" value={inputValues.user_password} className='form-control showInputText' onChange={(e) => {
                                checkPass(e)
                                // setInputValues({ ...inputValues, user_password: e.target.value })
                            }} />
                            {
                                showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                            }
                            {
                                inputValues.user_password &&
                                <p className='showpassword passStrength text-end' style={{
                                    color:
                                        passStrength === 'Too Short' ? 'var(--bs-danger)' : passStrength === 'Weak' ? 'var(--bs-orange)' : passStrength === 'Medium' ? 'var(--bs-yellow)' : passStrength === 'Good' ? 'var(--bs-primary)' : 'var(--bs-success)'
                                }}>{passStrength}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="status" className="col-4 col-form-label">Status:</label>
                    <div className="col-8">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="user_status_yes_no" onChange={(e) => setUserStatus(e.target.checked)} checked={userStatus} />
                            <label className="form-check-label" htmlFor="user_status_yes_no">{userStatus ? 'Active' : 'Inactive'}</label>
                        </div>
                    </div>
                </div>
             
                {/* {
                    (inputValues.user_password && passStrength !== 'Strong') &&
                    <tr>
                        <th></th>
                        <th></th>
                        <td className='passTd'>
                            <p className="error resetPass">Minimum 8 characters, atleast one special character, one uppercase, one lowercase letter and one number</p>
                        </td>
                    </tr>
                } */}
                {/* <tr>
                    <th>Confirm Password</th>
                    <th>:</th>
                    <td className='passTd'>
                        <input type={showConPass ? 'text' : 'password'} value={inputValues.conf_password} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, conf_password: e.target.value })} />
                        {
                            showConPass ? <VisibilityIcon onClick={() => setShowConPass(false)} /> : <VisibilityOffIcon onClick={() => setShowConPass(true)} />
                        }
                    </td>
                </tr> */}

                <Button variant='contained' className='editNsave' onClick={addUser}>Save</Button>
            </div>
        </div>
    )
}

export default UserAdd
