import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

function StackedMutiBar({ chartData }) {
    const history = useHistory()
    useEffect(() => {
        const initializeChart = () => {
            am4core.ready(function () {
                am4core.useTheme(am4themes_myTheme);
                am4core.addLicense('ch-custom-attribution');
                var chart = am4core.create("stackedMutiBar", am4charts.XYChart);
                chart.data = chartData;
    
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "imonth";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 30;
    
                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Success"; // Add y-axis label
                valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                });
    
                var series2 = chart.series.push(new am4charts.ColumnSeries());
                series2.columns.template.maxWidth = 30
                series2.dataFields.valueY = "success";
                series2.dataFields.categoryX = "imonth";
                series2.name = "Success";
                series2.clustered = false;
                series2.columns.template.tooltipText = "Inbound Success: [bold]{valueY}[/]";
                series2.columns.template.fillOpacity = 1;
    
                series2.columns.template.events.on("hit", function (ev) {
                    // console.log("clicked on ", ev.target);
                    history.push('/reports');
                }, this);
    
                var series = chart.series.push(new am4charts.LineSeries());
                series.dataFields.valueY = "faliure";
                series.dataFields.categoryX = "imonth";
                series.name = "failure";
                // series.clustered = false;
                // series.columns.template.tooltipText = "Inbound fail: [bold]{valueY}[/]";
            });
        }
        initializeChart()
    }, [chartData, history])

    function am4themes_myTheme(target) {
        if (target instanceof am4core.ColorSet) {
            target.list = [
                am4core.color("rgb(69, 54, 199)"),
                am4core.color("rgb(199, 54, 90)"),
            ];
        }
    }

    return (
        <div id="stackedMutiBar" className="chartInnerContainer stackedMutiBar"></div>
    )
}

// const data = [
//     {
//         "Month": "Jan",
//         "Success": 25,
//         "failure": 10,
//     },
//     {
//         "Month": "Feb",
//         "Success": 48,
//         "failure": 21,
//     },
//     {
//         "Month": "Mar",
//         "Success": 98,
//         "failure": 29,
//     },
//     {
//         "Month": "Apr",
//         "Success": 124,
//         "failure": 15,
//     },
//     {
//         "Month": "May",
//         "Success": 99,
//         "failure": 10,

//     }, {
//         "Month": "Jun",
//         "Success": 25,
//         "failure": 12,
//     },
//     {
//         "Month": "Jul",
//         "Success": 150,
//         "failure": 72,
//     },
//     {
//         "Month": "Aug",
//         "Success": 75,
//         "failure": 19,
//     },
//     {
//         "Month": "Sep",
//         "Success": 78,
//         "failure": 11,
//     },
//     {
//         "Month": "Oct",
//         "Success": 71,
//         "failure": 90,
//     },
//     {
//         "Month": "Nov",
//         "Success": 57,
//         "failure": 3,
//     },
//     {
//         "Month": "Dec",
//         "Success": 82,
//         "failure": 51,
//     }
// ];

export default StackedMutiBar
