import axios from 'axios'
import { BASE_URL } from '../config'
import appRoute from '../Route/appRoutes'

const instance = axios.create({
    baseURL: BASE_URL,
})

const accessExpired = () => {
    localStorage.clear();
    // alert('Login expired!');
    window.location.href = appRoute.LOGIN;
}

instance.interceptors.request.use(
    (request) => {
        if ( localStorage.userdata !== undefined ) {
            let accessToken = JSON.parse(localStorage.userdata).access_token;
            request.headers.Authorization = `Bearer ${accessToken}`;
            let expireTiming = JSON.parse(localStorage.userdata)['.expires'];
            let accessValid = new Date() <= new Date(expireTiming);
            if (accessValid) {
                return request
            } else {
                return accessExpired();
            }
        }
        return request
    }, (error) => {
        console.log(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        return response.data
    }, (error) => {
        console.log("response rejected", error)
        return error
    }
)

export default instance