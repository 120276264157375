import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableBody, TableRow, TableCell, Button, IconButton, TablePagination, TextField, Tooltip } from '@material-ui/core';
import { AddOutlined, EditOutlined, RefreshOutlined } from '@material-ui/icons';
// import { StoreContext } from '../../Store/Provider';
import { useHistory } from 'react-router-dom';
import http from '../../api';
import { NotificationManager } from 'react-notifications';
import appRoute from '../../Route/appRoutes';
import './users.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
import EnhancedTableHead from '../../component/table/EnhancedTableHead';
import { getComparator, stableSort, showSuccessMessage, showErrorMessage } from '../../component/table/function';

function Users() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    // const context = useContext(StoreContext);
    const history = useHistory();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('user_name');
    const [userList, setUserList] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [apiLoading, setApiLoading] = useState(false);

    useEffect(() => {
        getUsersList()
    }, [searchTerm, rowsPerPage, page, order, orderBy])

    const getUsersList = () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        setApiLoading(true);
        const params = {
            "SearchText": searchTerm.trim(),
            "PageSize": rowsPerPage,
            "CurrentPage": page + 1,
            "ActivationStatus": "",
            "SortOrder": order,
            "SortCol": orderBy
        }

        http.post(`api/v1/User/Get`,
            params)
            .then((res) => {
                setTotalRecords(res?.RecordCount ?? 0)
                setUserList(res?.Value ?? []);
                setApiLoading(false);
            }).catch((error) => {
                console.log(error);
                NotificationManager.error('Something went wrong', '', 3000);
                setApiLoading(false);
            })
    }

    const sendResetPasswordLink = (userEmail, userLoginId) => {
        http.post(`api/v1/User/RequestResetPassword?mail=${encodeURIComponent(userEmail)}&userid=${encodeURIComponent(userLoginId)}`, [])
            .then((res) => {
                if ( res?.Success ) {
                    showSuccessMessage(res?.ResultMessage)
                } else {
                    showErrorMessage(res?.ResultMessage || 'Some error occurred.');
                }
                setApiLoading(false);
            }).catch((error) => {
                console.log(error);
                showErrorMessage('Something went wrong');
                setApiLoading(false);
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    if (apiLoading) {
        return null;
    }

    const headCells = [
        { id: 'user_name', label: 'User Name', sortable: true },
        { id: 'user_email', label: 'Email', sortable: true },
        { id: 'user_loginid', label: 'Login Id', sortable: true },
        { id: 'user_contact', label: 'Contact', sortable: true },
        { id: 'user_rolename', label: 'Role', sortable: true },
        { id: 'createdon', label: 'Date Created', sortable: true }
    ];

    return (
        <div className={`mainSection integrationComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading withAddnew'>
                <span>Users</span>
                <Button variant='contained' onClick={() => {
                    history.push(appRoute.AddUSERS);
                }} endIcon={<AddOutlined />}>Add User</Button>
            </div>
            <div className='tableParent'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                label="Search"
                                variant="standard"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ width: '300px', float: 'right'}}
                            />
                        </div>
                    </div>
                </div>
                <TableContainer>
                    <Table stickyHeader>
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            action={true} />
                        <TableBody>
                            {
                                userList.length ?
                                    stableSort(userList, getComparator(order, orderBy))
                                        // reportsList
                                        .map((row, index) => {
                                            let count = (index + 1) + (1 * (page * rowsPerPage));
                                            let date = moment(row.createdon).format('DD-MM-yyy');
                                            return (
                                                <TableRow hover key={count}>
                                                    <TableCell align='center'>{count}</TableCell>
                                                    <TableCell title={row.user_name}>{row.user_name}</TableCell>
                                                    <TableCell title={row.user_email}>{row.user_email}</TableCell>
                                                    <TableCell title={row.user_loginid}>{row.user_loginid}</TableCell>
                                                    <TableCell title={row.user_contact}>{row.user_contact}</TableCell>
                                                    <TableCell title={row.user_rolename}>{row.user_rolename}</TableCell>
                                                    <TableCell title={date}>{date}</TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Reset Password">
                                                            <IconButton color="primary" aria-label="Reset Password" size="small" onClick={() => sendResetPasswordLink(row.user_email, row.user_loginid)}>
                                                                <RefreshOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit User">
                                                            <IconButton color="primary" aria-label="Edit Integration" size="small" onClick={() => { history.push(appRoute.EditUSERS, row) }}>
                                                                <EditOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={8} align={'center'}>No users found</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div >
    )
}

export default Users