import React from 'react';
import { useSelector } from 'react-redux';
import './logs.css';

function Logs() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    return (
        <div className={`mainSection logsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Logs</div>
            Coming Soon!
        </div>
    )
}

export default Logs
