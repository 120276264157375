import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import http from '../../api';
import appRoute from '../../Route/appRoutes';
import Chip from '@mui/material/Chip';
import { NotificationManager } from 'react-notifications';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import { removeSpaceReturnValue } from '../../utility/utils';
import './notifi.css';

function EditNotifi() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [email1, setEmail1] = useState("");
    const [emailArray1, setEmailArray1] = useState([]);
    const [emailError1, setEmailError1] = useState("");
    const [email2, setEmail2] = useState("");
    const [emailArray2, setEmailArray2] = useState([]);
    const [emailError2, setEmailError2] = useState("");
    const [email3, setEmail3] = useState("");
    const [emailArray3, setEmailArray3] = useState([]);
    const [emailError3, setEmailError3] = useState("");
    const [sms1, setSms1] = useState("");
    const [smsArray1, setSmsArray1] = useState([]);
    const [smsError1, setSmsError1] = useState("")
    const [sms2, setSms2] = useState("");
    const [smsArray2, setSmsArray2] = useState([]);
    const [smsError2, setSmsError2] = useState("")
    const [sms3, setSms3] = useState("");
    const [smsArray3, setSmsArray3] = useState([]);
    const [smsError3, setSmsError3] = useState("");
    const [inputValues, setInputValues] = useState({
        alert_handling_track_id: 0,
        alert_handling_track_name: '',
        alert_handling_track_desc: '',
        notification_interval: 0,
        escalation_00_emails: '',
        escalation_00_sms: '',
        escalation_01_emails: '',
        escalation_01_sms: '',
        escalation_02_emails: '',
        escalation_02_sms: '',
    });

    useEffect(() => {
        setLoading(true)
        setInputValues({
            ...inputValues,
            alert_handling_track_id: history.location.state.alert_handling_track_id,
            alert_handling_track_name: history.location.state.alert_handling_track_name ?? '',
            alert_handling_track_desc: history.location.state.alert_handling_track_desc ?? '',
            notification_interval: history.location.state.notification_interval ?? '',
            // escalation_00_emails: history.location.state.escalation_00_emails,
            // escalation_00_sms: history.location.state.escalation_00_sms,
            // escalation_01_emails: history.location.state.escalation_01_emails,
            // escalation_01_sms: history.location.state.escalation_01_sms,
            // escalation_02_emails: history.location.state.escalation_02_emails,
            // escalation_02_sms: history.location.state.escalation_02_sms,
        });
        setEmailArray1(removeSpaceReturnValue(history.location.state.escalation_00_emails, 'email'));
        setEmailArray2(removeSpaceReturnValue(history.location.state.escalation_01_emails, 'email'));
        setEmailArray3(removeSpaceReturnValue(history.location.state.escalation_02_emails, 'email'));
        setSmsArray1(removeSpaceReturnValue(history.location.state.escalation_00_sms, 'tel'));
        setSmsArray2(removeSpaceReturnValue(history.location.state.escalation_01_sms, 'tel'));
        setSmsArray3(removeSpaceReturnValue(history.location.state.escalation_02_sms, 'tel'));
        setLoading(false);
    }, [])

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    const isInList = (value, inputType) => {
        if (inputType === 'email1') {
            return emailArray1.includes(value);
        } else if (inputType === 'email2') {
            return emailArray2.includes(value);
        } else if (inputType === 'email3') {
            return emailArray3.includes(value);
        } else if (inputType === 'sms1') {
            return smsArray1.includes(value);
        } else if (inputType === 'sms2') {
            return smsArray2.includes(value);
        } else if (inputType === 'sms3') {
            return smsArray3.includes(value);
        }
    }

    const isEmail = (value) => {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(value);
    }

    const isValidEmail = (value, emailNo) => {
        let error = null;
        if (isInList(value, emailNo)) {
            error = `${value} has already been added.`;
        }
        if (!isEmail(value)) {
            error = `${value} is not a valid email address.`;
        }
        if (error) {
            if (emailNo === 'email1') {
                setEmailError1(error)
            } else if (emailNo === 'email2') {
                setEmailError2(error)

            } else if (emailNo === 'email3') {
                setEmailError3(error)
            }
            NotificationManager.error(error);
            return false;
        }
        return true;
    }

    const inputKeyDown = (evt, inputType) => {
        if (["Enter", ","].includes(evt.key)) {
            evt.preventDefault();
            if (inputType === 'email1') {
                let value = email1.trim();
                if (value && isValidEmail(value, inputType)) {
                    setEmailArray1([...emailArray1, value]);
                    setEmail1('')
                }
            } else if (inputType === 'email2') {
                let value = email2.trim();
                if (value && isValidEmail(value, inputType)) {
                    setEmailArray2([...emailArray2, value]);
                    setEmail2('')
                }
            } else if (inputType === 'email3') {
                let value = email3.trim();
                if (value && isValidEmail(value, inputType)) {
                    setEmailArray3([...emailArray3, value]);
                    setEmail3('')
                }
            } else if (inputType === 'sms1') {
                console.log(sms1)
                let value = sms1.trim();
                setSmsError1('');
                if (!isValidPhoneNumber(sms1)) {
                    setSmsError1(`${sms1} is not valid Number`);
                    NotificationManager.error(`${sms1} is not valid Number`);
                } else if (isInList(formatPhoneNumberIntl(value), inputType)) {
                    setSmsError1(`${value} has already been added.`);
                    NotificationManager.error(`${value} has already been added.`);
                } else if (value && isValidPhoneNumber(value)) {
                    setSmsArray1([...smsArray1, formatPhoneNumberIntl(value)]);
                    setSms1('')
                }
            } else if (inputType === 'sms2') {
                let value = sms2.trim();
                setSmsError2('');
                if (!isValidPhoneNumber(sms2)) {
                    setSmsError2(`${sms2} is not valid Number`);
                    NotificationManager.error(`${sms2} is not valid Number`);
                } else if (isInList(formatPhoneNumberIntl(value), inputType)) {
                    setSmsError2(`${value} has already been added.`);
                    NotificationManager.error(`${value} has already been added.`);
                } else if (value && isValidPhoneNumber(value)) {
                    setSmsArray2([...smsArray2, formatPhoneNumberIntl(value)]);
                    setSms2('')
                }
            } else if (inputType === 'sms3') {
                let value = sms3.trim();
                setSmsError3('');
                if (!isValidPhoneNumber(sms3)) {
                    setSmsError3(`${sms3} is not valid Number`);
                    NotificationManager.error(`${sms3} is not valid Number`);
                } else if (isInList(formatPhoneNumberIntl(value), inputType)) {
                    setSmsError3(`${value} has already been added.`);
                    NotificationManager.error(`${value} has already been added.`);
                } else if (value && isValidPhoneNumber(value)) {
                    setSmsArray3([...smsArray3, formatPhoneNumberIntl(value)]);
                    setSms3('')
                }
            }
        }
    }

    const emailPaste = (evt, emailNo) => {
        evt.preventDefault();
        let paste = evt.clipboardData.getData("text");
        let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
        if (emailNo === 'email1') {
            setEmail1(paste);
            setEmailError1('');
        } else if (emailNo === 'email2') {
            setEmail2(paste);
            setEmailError2('');
        } else if (emailNo === 'email3') {
            setEmail3(paste);
            setEmailError3('');
        }
        // if (isInList(paste, emailNo)) {
        //     error = `${emails} has already been added.`;
        //     if (emailNo === 'email1') {
        //         setEmailError1(error)
        //     } else if (emailNo === 'email2') {
        //         setEmailError2(error)
        //     } else if (emailNo === 'email3') {
        //         setEmailError3(error)
        //     }
        // } 
        if (emails) {
            // let toBeAdded = emails.filter(email => !isInList(email, emailNo));
            if (emailNo === 'email1') {
                // setEmailArray1([...emailArray1, ...toBeAdded]);
                // setEmail1('');
                setEmailError1('');
            } else if (emailNo === 'email2') {
                // setEmailArray2([...emailArray2, ...toBeAdded]);
                // setEmail2('');
                setEmailError2('');
            } else if (emailNo === 'email3') {
                // setEmailArray3([...emailArray3, ...toBeAdded]);
                // setEmail3('');
                setEmailError3('');
            }
        }
        //  else {
        //     if (emailNo === 'email1') {
        //         setEmail1(paste);
        //         setEmailError1(`${paste} is not a valid email address`)
        //     } else if (emailNo === 'email2') {
        //         setEmail2(paste);
        //         setEmailError2(`${paste} is not a valid email address`)
        //     } else if (emailNo === 'email3') {
        //         setEmail3(paste);
        //         setEmailError3(`${paste} is not a valid email address`)
        //     }
        //     // NotificationManager.error(`${paste} is not a valid email address`);
        // }
    }

    const escInput = (event, inputType) => {
        if (inputType === 'email1') {
            setEmail1(event.target.value);
            setEmailError1('');
        } else if (inputType === 'email2') {
            setEmail2(event.target.value);
            setEmailError2('');
        } else if (inputType === 'email3') {
            setEmail3(event.target.value);
            setEmailError3('');
        } else if (inputType === 'sms1') {
            setSms1(event);
            setSmsError1('');
        } else if (inputType === 'sms2') {
            setSms2(event);
            setSmsError2('');
        } else if (inputType === 'sms3') {
            setSms3(event);
            setSmsError3('');
        }
    }

    const handleDelete = (arrayType, index) => {
        if (arrayType === 'email1') {
            setEmailArray1((chips) => chips.filter((chip, i) => {
                if (i === index) {
                    return false;
                } else {
                    return true;
                }
            }));
            setEmailError1('');
        } else if (arrayType === 'email2') {
            setEmailArray2((chips) => chips.filter((chip, i) => {
                if (i === index) {
                    return false;
                } else {
                    return true;
                }
            }));
            setEmailError2('');
        } else if (arrayType === 'email3') {
            setEmailArray3((chips) => chips.filter((chip, i) => {
                if (i === index) {
                    return false;
                } else {
                    return true;
                }
            }));
            setEmailError3('');
        } else if (arrayType === 'sms1') {
            setSmsArray1((chips) => chips.filter((chip, i) => {
                if (i === index) {
                    return false;
                } else {
                    return true;
                }
            }));
            setSmsError1('');
        } else if (arrayType === 'sms2') {
            setSmsArray2((chips) => chips.filter((chip, i) => {
                if (i === index) {
                    return false;
                } else {
                    return true;
                }
            }));
            setSmsError2('');
        } else if (arrayType === 'sms3') {
            setSmsArray3((chips) => chips.filter((chip, i) => {
                if (i === index) {
                    return false;
                } else {
                    return true;
                }
            }));
            setSmsError3('');
        }
    }

    const chipArrayToInput = (data, arrayType, index) => {
        if (arrayType === 'email1') {
            if (email1) {
                let value = email1.trim();
                if (value && isValidEmail(value, arrayType)) {
                    setEmailArray1([...emailArray1, value]);
                    setEmail1(data);
                    handleDelete(arrayType, index);
                }
            } else {
                setEmail1(data);
                handleDelete(arrayType, index);
            }
        } else if (arrayType === 'email2') {
            if (email2) {
                let value = email2.trim();
                if (value && isValidEmail(value, arrayType)) {
                    setEmailArray2([...emailArray2, value]);
                    setEmail2(data);
                    handleDelete(arrayType, index);
                }
            } else {
                setEmail2(data);
                handleDelete(arrayType, index);
            }
        } else if (arrayType === 'email3') {
            if (email3) {
                let value = email3.trim();
                if (value && isValidEmail(value, arrayType)) {
                    setEmailArray3([...emailArray3, value]);
                    setEmail3(data);
                    handleDelete(arrayType, index);
                }
            } else {
                setEmail3(data);
                handleDelete(arrayType, index);
            }
        } else if (arrayType === 'sms1') {
            if (sms1) {
                let value = sms1.trim();
                setSmsError1('');
                if (!isValidPhoneNumber(sms1)) {
                    setSmsError1(`${sms1} is not valid Number`);
                    NotificationManager.error(`${sms1} is not valid Number`);
                } else if (isInList(formatPhoneNumberIntl(value), arrayType)) {
                    setSmsError1(`${value} has already been added.`);
                    NotificationManager.error(`${value} has already been added.`);
                } else if (value && isValidPhoneNumber(value)) {
                    setSmsArray1([...smsArray1, formatPhoneNumberIntl(value)]);
                    setSms1(data);
                    handleDelete(arrayType, index);
                }
            } else {
                setSms1(data);
                handleDelete(arrayType, index);
            }
        } else if (arrayType === 'sms2') {
            if (sms2) {
                let value = sms2.trim();
                setSmsError1('');
                if (!isValidPhoneNumber(sms2)) {
                    setSmsError2(`${sms2} is not valid Number`);
                    NotificationManager.error(`${sms2} is not valid Number`);
                } else if (isInList(formatPhoneNumberIntl(value), arrayType)) {
                    setSmsError2(`${value} has already been added.`);
                    NotificationManager.error(`${value} has already been added.`);
                } else if (value && isValidPhoneNumber(value)) {
                    setSmsArray2([...smsArray2, formatPhoneNumberIntl(value)]);
                    setSms2(data);
                    handleDelete(arrayType, index);
                }
            } else {
                setSms2(data);
                handleDelete(arrayType, index);
            }
        } else if (arrayType === 'sms3') {
            if (sms3) {
                let value = sms3.trim();
                setSmsError3('');
                if (!isValidPhoneNumber(sms3)) {
                    setSmsError3(`${sms3} is not valid Number`);
                    NotificationManager.error(`${sms3} is not valid Number`);
                } else if (isInList(formatPhoneNumberIntl(value), arrayType)) {
                    setSmsError3(`${value} has already been added.`);
                    NotificationManager.error(`${value} has already been added.`);
                } else if (value && isValidPhoneNumber(value)) {
                    setSmsArray3([...smsArray3, formatPhoneNumberIntl(value)]);
                    setSms3(data);
                    handleDelete(arrayType, index);
                }
            } else {
                setSms3(data);
                handleDelete(arrayType, index);
            }
        }
    }

    const onSave = () => {
        if (!emailArray1.length) {
            NotificationManager.error('Please fill atleast Escalation Email-1');
        } else if (!smsArray1.length) {
            NotificationManager.error('Please fill atleast Escalation SMS-1');
        } else {
            let finalEmail1 = '';
            emailArray1.forEach((ele, index) => {
                if (index < (emailArray1.length - 1)) {
                    finalEmail1 += `${ele},`
                } else {
                    finalEmail1 += `${ele}`
                }
            })
            let finalEmail2 = '';
            emailArray2.forEach((ele, index) => {
                if (index < (emailArray2.length - 1)) {
                    finalEmail2 += `${ele},`
                } else {
                    finalEmail2 += `${ele}`
                }
            })
            let finalEmail3 = '';
            emailArray3.forEach((ele, index) => {
                if (index < (emailArray3.length - 1)) {
                    finalEmail3 += `${ele},`
                } else {
                    finalEmail3 += `${ele}`
                }
            })
            let finalSms1 = '';
            smsArray1.forEach((ele, index) => {
                if (index < (smsArray1.length - 1)) {
                    // finalSms1 += `${ele.replaceAll(" ", "")}, `
                    finalSms1 += `${ele},`
                } else {
                    finalSms1 += `${ele}`
                }
            })
            let finalSms2 = '';
            smsArray2.forEach((ele, index) => {
                if (index < (smsArray2.length - 1)) {
                    finalSms2 += `${ele},`
                } else {
                    finalSms2 += `${ele}`
                }
            })
            let finalSms3 = '';
            smsArray3.forEach((ele, index) => {
                if (index < (smsArray3.length - 1)) {
                    finalSms3 += `${ele},`
                } else {
                    finalSms3 += `${ele}`
                }
            })
            const body = {
                "alert_handling_track_id": inputValues.alert_handling_track_id,
                "oper": "U",
                "alert_handling_track_name": inputValues.alert_handling_track_name,
                "alert_handling_track_desc": inputValues.alert_handling_track_desc,
                "notification_interval": inputValues.notification_interval,
                "escalation_00_emails": finalEmail1,
                "escalation_00_sms": finalSms1,
                "escalation_01_emails": finalEmail2,
                "escalation_01_sms": finalSms2,
                "escalation_02_emails": finalEmail3,
                "escalation_02_sms": finalSms3,
                "isactive": "Y",
                "createdby": 1
            };
            http.post(`api/v1/Alert/Post`,
                body)
                .then((res) => {
                    if (res.Success) {
                        NotificationManager.success(res.ResultMessage, '', 3000);
                        setTimeout(() => {
                            history.push(appRoute.NOTIFI)
                        }, 3500);
                    } else {
                        NotificationManager.error(res.ResultMessage, '', 3000);
                    }
                }).catch((error) => {
                    console.log(error)
                    NotificationManager.error('Something went wrong', '', 3000);
                })
        }
    }

    return (
        <div className={`mainSection integrationComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`}  >
            <div className='mainSectionHeading'>
                <span>Alert Handling</span>
            </div>
            <div className='container-fluid notifiPage'>
                <div className='row'>
                    <div className='col-12 infoEmail'>
                        <p>Note :-</p>
                        <ol>
                            <li>Use comma(,) or Enter to set values!</li>
                            <li>Star marked fields are necessary!</li>
                        </ol>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-4'>
                        <p>Track Name</p>
                    </div>
                    <div className='col-8'>
                        <input type='text' placeholder='Track Name' className='form-control' value={inputValues.alert_handling_track_name} onChange={(e) => setInputValues({ ...inputValues, alert_handling_track_name: e.target.value })} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-4'>
                        <p>Track Description</p>
                    </div>
                    <div className='col-8'>
                        <input type='text' placeholder='Track Description' className='form-control' value={inputValues.alert_handling_track_desc} onChange={(e) => setInputValues({ ...inputValues, alert_handling_track_desc: e.target.value })} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-4'>
                        <p>Interval</p>
                    </div>
                    <div className='col-8'>
                        <input type='text' placeholder='Interval' className='form-control' value={inputValues.notification_interval} onChange={(e) => setInputValues({ ...inputValues, notification_interval: e.target.value })} />
                    </div>
                </div>
                <fieldset className='fieldset'>
                    <legend>Escalation Email</legend>
                    <div className='row'>
                        <div className='col-4'>
                            <p>Escalation Email-1 <span><sup>*</sup></span></p>
                        </div>
                        <div className='col-8'>
                            {emailArray1.length ?
                                <ul className='chipList'>
                                    {emailArray1.map((data, index) => {
                                        return (
                                            <ListItem key={index} onClick={() => chipArrayToInput(data, 'email1', index)}>
                                                <Chip label={data} onDelete={() => handleDelete('email1', index)} />
                                            </ListItem>
                                        );
                                    })}
                                </ul>
                                :
                                ""
                            }
                            <input type='email' value={email1} placeholder='Escalation Email-1' className='form-control' onChange={(event) => escInput(event, 'email1')} onKeyDown={(event) => inputKeyDown(event, 'email1')} onPaste={(event) => emailPaste(event, 'email1')} />
                            {
                                emailError1 &&
                                <p className="emailError">{emailError1}</p>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <p>Escalation Email-2</p>
                        </div>
                        <div className='col-8'>
                            {emailArray2.length ?
                                <ul className='chipList'>
                                    {emailArray2.map((data, index) => {
                                        return (
                                            <ListItem key={index} onClick={() => chipArrayToInput(data, 'email2', index)}>
                                                <Chip label={data} onDelete={() => handleDelete('email2', index)} />
                                            </ListItem>
                                        );
                                    })}
                                </ul>
                                :
                                ""
                            }
                            <input type='email' value={email2} placeholder='Escalation Email-2' className='form-control' onChange={(event) => escInput(event, 'email2')} onKeyDown={(event) => inputKeyDown(event, 'email2')} onPaste={(event) => emailPaste(event, 'email2')} />
                            {
                                emailError2 &&
                                <p className="emailError">{emailError2}</p>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <p>Escalation Email-3</p>
                        </div>
                        <div className='col-8'>
                            {emailArray3.length ?
                                <ul className='chipList'>
                                    {emailArray3.map((data, index) => {
                                        return (
                                            <ListItem key={index} onClick={() => chipArrayToInput(data, 'email3', index)}>
                                                <Chip label={data} onDelete={() => handleDelete('email3', index)} />
                                            </ListItem>
                                        );
                                    })}
                                </ul>
                                :
                                ""
                            }
                            <input type='email' value={email3} placeholder='Escalation Email-3' className='form-control' onChange={(event) => escInput(event, 'email3')} onKeyDown={(event) => inputKeyDown(event, 'email3')} onPaste={(event) => emailPaste(event, 'email3')} />
                            {
                                emailError3 &&
                                <p className="emailError">{emailError3}</p>
                            }
                        </div>
                    </div>
                </fieldset>
                <fieldset className='fieldset'>
                    <legend>Escalation SMS</legend>
                    <div className='row'>
                        <div className='col-4'>
                            <p>Escalation SMS-1 <span><sup>*</sup></span></p>
                        </div>
                        <div className='col-8'>
                            {smsArray1.length ?
                                <ul className='chipList'>
                                    {smsArray1.map((data, index) => {
                                        return (
                                            <ListItem key={index} onClick={() => chipArrayToInput(data, 'sms1', index)}>
                                                <Chip label={data} onDelete={() => handleDelete('sms1', index)} />
                                            </ListItem>
                                        );
                                    })}
                                </ul>
                                :
                                ""
                            }
                            <PhoneInput international
                                // defaultCountry="IN" countryCallingCodeEditable={false} 
                                type='tel' value={sms1} placeholder='Escalation SMS-1' className={sms1 ? ((sms1 && isValidPhoneNumber(sms1)) ? 'form-control telValid' : 'form-control telInValid') : 'form-control'} error={sms1 ? (isValidPhoneNumber(sms1) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                onChange={(setSms1) => escInput(setSms1, 'sms1')} onKeyDown={(event) => inputKeyDown(event, 'sms1')} />
                            {
                                smsError1 &&
                                <p className="emailError">{smsError1}</p>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <p>Escalation SMS-2</p>
                        </div>
                        <div className='col-8'>
                            {smsArray2.length ?
                                <ul className='chipList'>
                                    {smsArray2.map((data, index) => {
                                        return (
                                            <ListItem key={index} onClick={() => chipArrayToInput(data, 'sms2', index)}>
                                                <Chip label={data} onDelete={() => handleDelete('sms2', index)} />
                                            </ListItem>
                                        );
                                    })}
                                </ul>
                                :
                                ""
                            }
                            <PhoneInput international
                                // defaultCountry="IN" countryCallingCodeEditable={false}
                                type='tel' value={sms2} placeholder='Escalation SMS-2' className={sms2 ? ((sms2 && isValidPhoneNumber(sms2)) ? 'form-control telValid' : 'form-control telInValid') : 'form-control'} onChange={(setSms2) => escInput(setSms2, 'sms2')} onKeyDown={(event) => inputKeyDown(event, 'sms2')} />
                            {
                                smsError2 &&
                                <p className="emailError">{smsError2}</p>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <p>Escalation SMS-3</p>
                        </div>
                        <div className='col-8'>
                            {smsArray3.length ?
                                <ul className='chipList'>
                                    {smsArray3.map((data, index) => {
                                        return (
                                            <ListItem key={index} onClick={() => chipArrayToInput(data, 'sms3', index)}>
                                                <Chip label={data} onDelete={() => handleDelete('sms3', index)} />
                                            </ListItem>
                                        );
                                    })}
                                </ul>
                                :
                                ""
                            }
                            <PhoneInput international
                                // defaultCountry="+91" countryCallingCodeEditable={false} 
                                type='tel' value={sms3} placeholder='Escalation SMS-3' className={sms3 ? ((sms3 && isValidPhoneNumber(sms3)) ? 'form-control telValid' : 'form-control telInValid') : 'form-control'} onChange={(setSms3) => escInput(setSms3, 'sms3')} onKeyDown={(event) => inputKeyDown(event, 'sms3')} />
                            {
                                smsError3 &&
                                <p className="emailError">{smsError3}</p>
                            }
                        </div>
                    </div>
                </fieldset>
                <div className='row'>
                    <div className='col-12'>
                        <Button onClick={onSave} variant='contained'>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditNotifi