import React, { useState, useEffect, useContext } from 'react'
import { Button } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { StoreContext } from '../../Store/Provider';
import { useHistory } from 'react-router-dom';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import http from '../../api';
import appRoute from '../../Route/appRoutes';
import { useSelector } from 'react-redux';

function SourceEdit() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const context = useContext(StoreContext);
    const [loading, setLoading] = useState(false);
    const [connectionList, setConnectionList] = useState([]);
    const [endPointTypeList, setEndPointTypeList] = useState([]);
    // const [showPass, setShowPass] = useState(false);
    // const [showConPass, setShowConPass] = useState(false);
    const [inputValues, setInputValues] = useState({
        source_endpoint_id: '',
        source_endpoint_name: '',
        source_endpoint_connection: '',
        source_endpoint_type_id: '',
        source_endpoint_working_path: '',
        source_endpoint_archive_path: '',
        source_endpoint_absolute_path: '',
        // connection_name: '',
        // connection_url:'',
        // connection_username:'',
        // connection_password: '',
        // connection_confPassword: ''
    });

    useEffect(() => {
        setLoading(true)
        setInputValues({
            ...inputValues,
            source_endpoint_id: history.location.state.source_endpoint_id,
            source_endpoint_name: history.location.state.source_endpoint_name,
            source_endpoint_connection: history.location.state.source_endpoint_connection,
            source_endpoint_type_id: history.location.state.source_endpoint_type_id,
            source_endpoint_working_path: history.location.state.source_endpoint_working_path,
            source_endpoint_archive_path: history.location.state.source_endpoint_archive_path,
            source_endpoint_absolute_path: history.location.state.source_endpoint_absolute_path,
            // connection_name: history.location.state.connection_name,
            // connection_url: history.location.state.connection_url,
            // connection_username: history.location.state.connection_username,
            // connection_password: history.location.state.connection_password,
            // connection_confPassword: history.location.state.connection_password
        });
        setLoading(false);
    }, [])


    useEffect(async () => {
        setLoading(true);
        await getConn();
        await getEndPointType();
        setLoading(false);
    }, [])

    const getConn = async () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;

        const body = {
            "SearchText": "",
            "PageSize": 20,
            "CurrentPage": 1,
            "Id": null,
            "ActivationStatus": null,
            "Organization": null,
            "SortOrder": "Desc",
            "SortCol": "Connection_Name"
        };

        http.post(`api/v1/connection/get`,
            body)
            .then((res) => {
                setConnectionList(res?.Value ?? []);
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('Something went wrong', '', 3000);
            })
    }

    const getEndPointType = () => {
        http.post(`api/v1/Endpoint/get/endpointtype`)
            .then((res) => {
                setEndPointTypeList(res?.Value ?? [])
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('End Point Type List API Fails', '', 3000);
            })
    }

    const editSource = () => {
        // if (inputValues.connection_password !== inputValues.connection_confPassword) {
        //     NotificationManager.error('Confirm Password does not match', '', 3000);         
        // } else {     
        // }
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        const body = {
            "source_endpoint_id": inputValues.source_endpoint_id,
            "oper": "U",
            "source_endpoint_connection": inputValues.source_endpoint_connection,
            "source_endpoint_name": inputValues.source_endpoint_name,
            "source_endpoint_type_id": inputValues.source_endpoint_type_id,
            "source_endpoint_working_path": inputValues.source_endpoint_working_path,
            "source_endpoint_archive_path": inputValues.source_endpoint_archive_path,
            "source_endpoint_absolute_path": inputValues.source_endpoint_absolute_path,
            // "connection_name": inputValues.connection_name,
            // "connection_url": inputValues.connection_url,
            // "connection_username": inputValues.connection_username,
            // "connection_password": inputValues.connection_password,
            "isactive": "Y",
            "createdby": 1
        };
        http.post(`api/v1/Endpoint/post/source`,
            body)
            .then((res) => {
                if (res.Success) {
                    NotificationManager.success(res.ResultMessage, '', 3000);
                    setTimeout(() => {
                        history.push(appRoute.Source)
                    }, 3500);
                } else {
                    NotificationManager.error(res.ResultMessage, '', 3000);
                }
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('Something went wrong', '', 3000);
            })
    }

    if (loading) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Edit Source End Point</div>
            <div className='sorceDataDiv'>
                {
                    Object.keys(history.location.state).length !== 0 &&
                    <table className='source'>
                        <tbody>
                            <tr>
                                <th>End Point Name</th>
                                <th>:</th>
                                <td>
                                    <input type='text' value={inputValues.source_endpoint_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_name: e.target.value })} />
                                </td>
                            </tr>
                            <tr>
                                <th>Working Path</th>
                                <th>:</th>
                                <td>
                                    <input type='text' value={inputValues.source_endpoint_working_path} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_working_path: e.target.value })} />
                                </td>
                            </tr>
                            <tr>
                                <th>Archieve Path</th>
                                <th>:</th>
                                <td>
                                    <input type='text' value={inputValues.source_endpoint_archive_path} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_archive_path: e.target.value })} />
                                </td>
                            </tr>
                            <tr>
                                <th>Absolute Path</th>
                                <th>:</th>
                                <td>
                                    <input type='text' value={inputValues.source_endpoint_absolute_path} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_absolute_path: e.target.value })} />
                                </td>
                            </tr>
                            <tr>
                                <th>End Point Connection</th>
                                <th>:</th>
                                <td>
                                    <select className='form-select' value={inputValues.source_endpoint_connection} onChange={(e) => setInputValues({ ...inputValues, source_endpoint_connection: e.target.value })}>
                                        {
                                            connectionList?.length !== 0 &&
                                            connectionList?.map((ele, index) => {
                                                return (
                                                    <option value={ele.connection_id} key={index}>{ele.connection_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {/* <input type='text' value={inputValues.source_endpoint_connection} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_connection: e.target.value })} /> */}
                                </td>
                            </tr>
                            <tr>
                                <th>End Point Type</th>
                                <th>:</th>
                                <td>
                                    <select value={inputValues.source_endpoint_type_id} onChange={(e) => setInputValues({ ...inputValues, source_endpoint_type_id: e.target.value })} className='form-select' >
                                        {
                                            endPointTypeList?.length !== 0 &&
                                            endPointTypeList?.map((ele, index) => {
                                                return (
                                                    <option value={ele.Endpoint_type_id} key={index} label={ele.Endpoint_type_name} />
                                                )
                                            })
                                        }
                                    </select>
                                    {/* <input type='text' value={inputValues.source_endpoint_type_id} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, source_endpoint_type_id: e.target.value })} /> */}
                                </td>
                            </tr>
                            {/* <tr>
                                <th>Connection Name</th>
                                <th>:</th>
                                <td><input type='text' value={inputValues.connection_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_name: e.target.value })} /></td>
                            </tr> */}
                            {/*<tr>
                                <th>Connection URL</th>
                                <th>:</th>
                                <td><input type='password' value={inputValues.connection_url} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_url: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>User Name</th>
                                <th>:</th>
                                <td><input type='password' value={inputValues.connection_username} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_username: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Connection Password</th>
                                <th>:</th>
                                <td className='passTd'>
                                    <input type={showPass ? 'text' : 'password'} value={inputValues.connection_password} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_password: e.target.value })} />
                                    {
                                        showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Confirm Password</th>
                                <th>:</th>
                                <td className='passTd'>
                                    <input type={showConPass ? 'text' : 'password'} value={inputValues.connection_confPassword} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_confPassword: e.target.value })} />
                                    {
                                        showConPass ? <VisibilityIcon onClick={() => setShowConPass(false)} /> : <VisibilityOffIcon onClick={() => setShowConPass(true)} />
                                    }
                                </td>
                            </tr>*/}
                        </tbody>
                    </table>
                }
                <Button variant='contained' className='editNsave' onClick={editSource}>Save</Button>
            </div>
        </div>
    )
}

export default SourceEdit

