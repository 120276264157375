import Layout from '../component/Layout/Layout';
import InternalItems from "../component/Sidebar/InternalItems";
import MainItemsList from "../component/Sidebar/MainItemsList";
import Login from "../views/LoginComponent/Login";
import ChangePassword from "../views/changePassword/ChangePassword";
import Connections from '../views/connections/Connections';
import ConnectionsAdd from "../views/connections/ConnectionsAdd";
import ConnectionsEdit from '../views/connections/ConnectionsEdit';
import AnalyticsDashboard from '../views/dashboard/AnalyticsDashboard';
import EndpointAdd from "../views/destination/EndpointAdd";
import Endpoints from '../views/destination/Endpoints';
import ForgotPassword from "../views/forgotPassword/ForgotPassword";
import ResetPassword from "../views/forgotPassword/ResetPassword";
import Integration from '../views/integration/Integration';
import IntegrationAdd from "../views/integration/IntegrationAdd";
import DestinationEnd from '../views/integration/integrationDetails/DestinationEnd';
import Notification from '../views/integration/integrationDetails/Notification';
import ScheduleTimer from '../views/integration/integrationDetails/ScheduleTimer';
import SourceEnd from '../views/integration/integrationDetails/SourceEnd';
import Logs from '../views/logs/Logs';
import { default as MappingCreate } from "../views/message/MappingStream/Create";
import { default as MappingEdit } from "../views/message/MappingStream/Edit";
import { default as MappingList } from "../views/message/MappingStream/List";
import Create from "../views/message/MessageType/Create";
import Edit from "../views/message/MessageType/Edit";
import List from "../views/message/MessageType/List";
import AddNotifi from "../views/notifi/AddNotifi";
import EditNotifi from "../views/notifi/EditNotifi";
import Notifi from '../views/notifi/Notifi';
import Organizations from "../views/organizations/Organizations";
import OrganizationsAdd from "../views/organizations/OrganizationsAdd";
import OrganizationsEdit from "../views/organizations/OrganizationsEdit";
import Reports from "../views/reports/Reports";
import ReportsLog from "../views/reports/ReportsLog";
import Source from "../views/source/Source";
import SourceAdd from '../views/source/SourceAdd';
import SourceEdit from "../views/source/SourceEdit";
import UserAdd from "../views/users/UserAdd";
import UserEdit from "../views/users/UserEdit";
import Users from '../views/users/Users';
import appRoute from "./appRoutes";

const universalRoute = [
    {
        path: appRoute.LOGIN,
        component: () => <Login />,
        isExact: true,
        iaPrivate: false,
    },
    {
        path: appRoute.FORGOTPASS,
        component: () => <ForgotPassword />,
        isExact: true,
        iaPrivate: false,
    },
    {
        path: appRoute.RESETPASS,
        component: () => <ResetPassword />,
        isExact: true,
        iaPrivate: false,
    }
]


const controlledRoute = [
    {
        path: appRoute.CHANGEPASS,
        component: () => <Layout main={ChangePassword()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        iaPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.DASHBOARD,
        component: () => <Layout main={AnalyticsDashboard()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: true,
    },
    {
        path: appRoute.INTEGRATION,
        component: () => <Layout main={Integration()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.AddIntegration,
        component: () => <Layout main={IntegrationAdd()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.EditIntegration,
        component: () => <Layout main={IntegrationAdd()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.Connections,
        component: () => <Layout main={Connections()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.AddCONN,
        component: () => <Layout main={ConnectionsAdd()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.EditConn,
        component: () => <Layout main={ConnectionsEdit()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.Org,
        component: () => <Layout main={Organizations()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.AddOrg,
        component: () => <Layout main={OrganizationsAdd()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.EditOrg,
        component: () => <Layout main={OrganizationsEdit()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.LOGS,
        component: () => <Layout main={Logs()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.USERS,
        component: () => <Layout main={Users()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.AddUSERS,
        component: () => <Layout main={UserAdd()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.EditUSERS,
        component: () => <Layout main={UserEdit()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.NOTIFICATION,
        component: () => <Layout main={Notification()} sidebarItemList={InternalItems()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.TIMER,
        component: () => <Layout main={ScheduleTimer()} sidebarItemList={InternalItems()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.SOURCE_END_POINT,
        component: () => <Layout main={SourceEnd()} sidebarItemList={InternalItems()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.DESTINATION_END_POINT,
        component: () => <Layout main={DestinationEnd()} sidebarItemList={InternalItems()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.Source,
        component: () => <Layout main={Source()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.EditSource,
        component: () => <Layout main={SourceEdit()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.AddSource,
        component: () => <Layout main={SourceAdd()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.Endpoints,
        component: () => <Layout main={Endpoints()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.AddEndpoint,
        component: () => <Layout main={EndpointAdd()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.EditEndpoint,
        component: () => <Layout main={EndpointAdd()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.Reports,
        component: () => <Layout main={Reports()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: true,
    },
    {
        path: appRoute.ReportsLog,
        component: () => <Layout main={ReportsLog()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: true,
    },
    {
        path: appRoute.NOTIFI,
        component: () => <Layout main={Notifi()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.AddNotifi,
        component: () => <Layout main={AddNotifi()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.EditNotifi,
        component: () => <Layout main={EditNotifi()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.MessageTypeList,
        component: () => <Layout main={List()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.MessageTypeCreate,
        component: () => <Layout main={Create()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.MessageTypeEdit,
        component: () => <Layout main={Edit()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.MessageMappingStreamList,
        component: () => <Layout main={MappingList()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.MessageMappingStreamCreate,
        component: () => <Layout main={MappingCreate()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
    {
        path: appRoute.MessageMappingStreamEdit,
        component: () => <Layout main={MappingEdit()} sidebarItemList={MainItemsList()} />,
        isExact: true,
        isPrivate: true,
        userAccess: false,
    },
]


export { universalRoute, controlledRoute };
// export default routeConfig