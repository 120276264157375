import React, { useState } from 'react'
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import http from '../../../api';
import { NotificationManager } from 'react-notifications';
import appRoute from '../../../Route/appRoutes';

const Create = () => {
	const closeSidebar = useSelector(state => state.reducer.closeSidebar)
	const history = useHistory();
	const [formData, setFormData] = useState({
		name: '',
		url: '',
		stream_description: '',
	});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

    const isValidURL = (url) => {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // Protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // Query string
          '(\\#[-a-z\\d_]*)?$',
          'i' // Case insensitive
        );
        return pattern.test(url);
    }

	const handleSubmit = (e) => {
		e.preventDefault();
		const {name, url, stream_description} = formData;
        if ( ! name.length && ! url.length ) {

            if ( ! isValidURL(url) ) {
                NotificationManager.error('Please enter valid URL', '', 3000);
                return
            }

            const body = {
                oper: 'I',
                mapping_stream_name: name,
                mapping_stream_url: url,
                mapping_stream_desc: stream_description
            };

            http.post(`api/v1/Message/MappingStream/POST`, body)
            .then((res) => {
                if (res.Success) {
                    NotificationManager.success(res.ResultMessage, '', 3000);
                    setTimeout(() => {
                        history.push(appRoute.MessageMappingStreamList)
                    }, 3500);

                    e.target.reset();
                } else {
                    NotificationManager.error(res.ResultMessage, '', 3000);
                }
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('Something went wrong', '', 3000);
            })
        } else {
            NotificationManager.error('Please fill all fields', '', 3000);
        }
	};

	return (
		<div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
			<div className='mainSectionHeading'>Add Mapping Stream</div>
			<div className='sorceDataDiv'>
				<form onSubmit={handleSubmit}>
					<div className="row mb-3">
						<div className="col-6">
							<label htmlFor="name" className="form-label">Name</label>
							<input type='text' className='form-control' id="name" name="name" onChange={handleChange} />
						</div>
					</div>

                    <div className="row mb-3">
                        <div className="col-6">
                            <label htmlFor="url" className="form-label">URL</label>
							<input type='text' className='form-control' id="url" name="url" onChange={handleChange} />
						</div>
                    </div>

                    <div className="row mb-3">
						<div className="col-6">
							<label htmlFor="streamDescription" className="form-label">Stream Description</label>
                            <textarea class="form-control" name="stream_description" id="streamDescription" rows="3" onChange={handleChange}></textarea>
						</div>
					</div>

					<Button type="submit" variant='contained' className='editNsave'>Save</Button>
				</form>
			</div>
		</div>
	)
}
export default Create;