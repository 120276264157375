import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { Link, useHistory } from 'react-router-dom';
import { StoreContext } from '../../Store/Provider';
import http from '../../api';
import { BASE_URL, ProjectName } from '../../config';
import './login.css';

function Login(props) {

    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [showPass, setShowPass] = useState(false);
    // const [loading, setLoading] = useState(false)
    const history = useHistory();
    const context = useContext(StoreContext);

    useEffect(() => {
        localStorage.userdata && history.push('/integrations');
    }, [history])

    const getSidebar = (userId) => {
        http.get(`api/v1/User/Menu/${userId}`)
            .then(res => {
                // localStorage.setItem("sidebarData", JSON.stringify(res.data.Value));
                context.setmenuList(res?.Value ?? [])
                if (res.Value.length === 0) {
                    history.push('/')
                    NotificationManager.error("No route found");
                } else {
                    history.push(res.Value[0].itemLink.toLowerCase())
                }
                setOpen(false);
                NotificationManager.success("Login successfully!!")
            })
            .catch(err => console.log(err))
    }

    const onLogin = () => {
        if (username && password) {
            // setLoading(true)
            setOpen(true);
            const params = new URLSearchParams()
            params.append("grant_type", "password")
            params.append('password', password)
            params.append("username", username)

            axios
                .post(BASE_URL + 'Token',
                    params).then((res) => {
                        localStorage.setItem("userdata", JSON.stringify(res.data))
                        // let accessToken = JSON.parse(localStorage.userdata).access_token;
                        getSidebar(res.data.id);
                        // history.push(appRoute.DASHBOARD)
                    }).catch((error) => {
                        console.log(error)
                        setOpen(false);
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                            error?.response?.status === 400 ?
                                NotificationManager.error(error?.response?.data?.error_description) : NotificationManager.error(error.response?.data?.Message);
                        } else {
                            console.log(error);
                            NotificationManager.error("Login failed! Something went wrong");
                        }
                    })
        } else {
            NotificationManager.error("Please fill all fields")
        }
    }

    return (
        <div className='loginComponent container-fluid' style={{ background: `url("/${ProjectName}/Assets/LoginBackground.jpg") 0% bottom / cover` }}>
            <div className='row'>
                <div className='col px-0'>
                    <img src={`/${ProjectName}/Assets/Logo.png`} alt='logo' />

                    <div className='loginFormSection'>
                        <div className='loginDiv'>
                            <h5>Login to get Started!</h5>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="User Id"
                                    value={username}
                                    onChange={(e) => { setUsername(e.target.value) }}

                                />
                                <label htmlFor="floatingInput">User Id</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type={showPass ? 'text' : 'password'}
                                    className="form-control"
                                    id="floatingPassword"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                                <label htmlFor="floatingPassword">Password</label>
                                {
                                    showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                                }
                            </div>
                            <Link to='/ForgotPassword'>Forgot Password</Link>
                            <button type="button" className="btn btn-lg mt-3" onClick={onLogin} >Login</button>
                        </div>
                    </div>

                </div>
                {/* <div className='col-6 px-0 loginbannerSection'>
                </div> */}
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Login
